import { render, staticRenderFns } from "./traditional.vue?vue&type=template&id=76cf43cb&scoped=true"
import script from "./traditional.vue?vue&type=script&lang=js"
export * from "./traditional.vue?vue&type=script&lang=js"
import style0 from "./traditional.vue?vue&type=style&index=0&id=76cf43cb&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76cf43cb",
  null
  
)

export default component.exports