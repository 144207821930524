<template>
  <div>
    <div class="home">
      <NavBar type="my"/>
      <div class="content">
        <div class="banner flex_column">
          <div class="title">云晰营销管理软件</div>
          <div class="last_title">基于流量、产品和服务优势，打通消费医疗医疗营销链，助力企业业绩全面提升</div>
          <div class="banner_btn" @click="openAppoint">
            <div class="name">预约体验</div>
            <img class="img" src="../assets/banner_right.png" alt="">
          </div>
        </div>
        <div class="info flex_column">
          <div class="first_title">垂直支持六大消费医疗经营场景</div>
          <div class="title_icon"></div>
          <div class="last_title">以企业为核心，个性化定制多经营场景，全面满足企业所需</div>
          <div class="list">
            <div class="item">
              <img class="img" src="../assets/icon1.png" alt="">
              <div class="name">医美</div>
            </div>
            <div class="item">
              <img class="img" src="../assets/icon2.png" alt="">
              <div class="name">口腔</div>
            </div>
            <div class="item">
              <img class="img" src="../assets/icon3.png" alt="">
              <div class="name">植发</div>
            </div>
            <div class="item">
              <img class="img" src="../assets/icon4.png" alt="">
              <div class="name">眼科</div>
            </div>
            <div class="item">
              <img class="img" src="../assets/icon5.png" alt="">
              <div class="name">妇产</div>
            </div>
            <div class="item">
              <img class="img" src="../assets/icon6.png" alt="">
              <div class="name">产康</div>
            </div>
          </div>
          <div class="first_title">云晰全链路业绩增长方案</div>
          <div class="title_icon"></div>
          <div class="last_title">赋能行业中最小生产力单元“咨询师”，一体化解决行业营销难题</div>
          <div class="info_nav">
            <div class="left">
              <div class="flex">获客增长方案</div>
              <p>全频道巨量媒体公域 为企业提供海量商机</p>
            </div>
            <div class="center">
              <div class="flex">成交转化方案</div>
              <p>私域落地线索  承接   联动   持续沉淀</p>
            </div>
            <div class="right">
              <div class="flex">科学管理方案</div>
              <p>围绕用户数据，以增长为目标 <br>
                提供完善的客户成功服务体系</p>
            </div>
          </div>
          <div class="info_content">
            <img class="one_img" src="https://resource.51beauty.com.cn/7f26a75a-5a12-4956-b355-71f9752c4726?imageslim" alt="">
            <div class="solid">
              <div class="val">无感抓取线索</div>
              <img class="solid_icon" src="https://resource.51beauty.com.cn/e388e3ca-4bba-4eda-9bc1-433d6c36d9d1?imageslim" alt="">
            </div>
            <img class="two_img" src="https://resource.51beauty.com.cn/ac840cb4-4ad6-4c8e-aa8a-219d18080bb9?imageslim" alt="">
            <div class="solid">
              <div class="val">多维度数据分析 <br> 助力有效经营</div>
              <img class="solid_icon" src="https://resource.51beauty.com.cn/e388e3ca-4bba-4eda-9bc1-433d6c36d9d1?imageslim" alt="">
            </div>
            <img class="three_img" src="https://resource.51beauty.com.cn/2a3fa282-a822-42d8-aa09-e1315c838987?imageslim" alt="">
          </div>
          <div class="content_btn flex" @click="openAppoint">
            <div>预约体验</div>
            <img src="https://resource.51beauty.com.cn/14fa7c98-5c70-45da-9dba-1842be7e7242?imageslim" alt="">
          </div>
        </div>
        <div class="custom flex_column">
          <div class="first_title">获客云</div>
          <div class="custom_solid"></div>
          <div class="last_title">基于公私域打通的一站式获客增长方案</div>
          <div class="list">
            <div class="item flex_column">
              <img src="https://resource.51beauty.com.cn/bea71e55-6f58-41ec-b4b7-44fda8bdd145?imageslim" alt="">
              <div class="name">关系好</div>
              <div class="infos">深度对接媒体进行战略合作,利用媒介优势整合多种触达能力。</div>
            </div>
            <div class="item flex_column">
              <img src="https://resource.51beauty.com.cn/447fca9f-44f1-41c2-97cd-33ba89febcc5?imageslim" alt="">
              <div class="name">经验多</div>
              <div class="infos">自身投放团队持续协作跟进,团队多年深耕消费医疗领域。</div>
            </div>
            <div class="item flex_column">
              <img src="https://resource.51beauty.com.cn/f5cfef5d-7f45-4a06-90d8-6d862b16b7b1?imageslim" alt="">
              <div class="name">落地稳</div>
              <div class="infos">依托媒介优势搭建流量矩阵,以小程序为载体，无痕套电。</div>
            </div>
            <div class="item flex_column">
              <img src="https://resource.51beauty.com.cn/31b7ae0e-ec09-4d5e-8759-106305d8a5ea?imageslim" alt="">
              <div class="name">数据通</div>
              <div class="infos">打通客户和媒体双向数据，进行数据的多元化落地收集。</div>
            </div>
            <div class="item flex_column">
              <img src="https://resource.51beauty.com.cn/e4d6422a-c94f-4926-96c7-8d37fa34c48a?imageslim" alt="">
              <div class="name">用户准</div>
              <div class="infos">抓取客户数据获取精准画像,进行客户数据的去中心化收集。</div>
            </div>
          </div>
          <div class="custom_content">
            <div class="left">
              <img src="https://resource.51beauty.com.cn/c3fa3940-dd99-4c5b-8ab4-cb94d3f7bcf4?imageslim" alt="">
              <div class="title">
                公私域系统一体化 <br>
                提升投放回报收益率（ROI）
              </div>
            </div>
            <div class="right">
              <div class="li">
                <img class="li_img" src="https://resource.51beauty.com.cn/06ab4c26-2b63-4224-a5ab-027bc4ceee47?imageslim" alt="">
                <div class="title">海量线索抓取</div>
                <div class="cont">核心一手媒介资源+全渠道覆盖，海量优质数据线索精准抓取</div>
              </div>
              <div class="right_solid"></div>
              <div class="li">
                <img class="li_img" src="https://resource.51beauty.com.cn/f9f74d26-8ce1-43f4-bd14-11e6c0aa3c02?imageslim" alt="">
                <div class="title">行业智能匹配</div>
                <div class="cont">多标签组合筛选数据线索，按需匹配，快速获得目标客户</div>
              </div>
              <div class="right_solid"></div>
              <div class="li">
                <img class="li_img" src="https://resource.51beauty.com.cn/b1a9653a-405f-4d61-9527-cc4fc6d8b022?imageslim" alt="">
                <div class="title">企微深度运营</div>
                <div class="cont">流量统一沉淀到私域，通过企业微信深度运营,多种营销触达促成转化</div>
              </div>
            </div>
          </div>
        </div>
        <div class="cloud flex_column">
          <div class="first_title">转化云</div>
          <div class="custom_solid"></div>
          <div class="last_title">基于企业微信SCRM的成交转化方案</div>
          <div class="list">
            <div class="item">
              <img src="https://resource.51beauty.com.cn/8e361207-d8a5-4395-b631-4f76448f3a0b?imageslim" alt="">
              <div class="title">智能推荐系统</div>
              <div class="item_info">个人IP 资质证书 智能海报 <br>
                案例库 名义包装 产品项目</div>
            </div>
            <div class="item">
              <img src="https://resource.51beauty.com.cn/fb097cdc-0f80-4189-a8a3-9af451397aaf?imageslim" alt="">
              <div class="title">内容营销系统</div>
              <div class="item_info">海报素材 文章素材 短视频素材 <br>
                在线直播朋友圈素材 营销数据管理</div>
            </div>
            <div class="item">
              <img class="img1" src="https://resource.51beauty.com.cn/27d3e97b-3a14-4a68-aa33-9af62df62e6d?imageslim" alt="">
              <div class="title">客户管理系统</div>
              <div class="item_info">线索管理 AI管理 跟进管理 <br>
                IM互动管理 客户数据看板</div>
            </div>
            <div class="item">
              <img src="https://resource.51beauty.com.cn/ddbbc96b-b1d6-41eb-a930-b55f8050ffab?imageslim" alt="">
              <div class="title">内容社区</div>
              <div class="item_info">咨询师名片 变美内容种草 短视频 <br>
                在线咨询 AI测脸 营销活动</div>
            </div>
            <div class="item">
              <img src="https://resource.51beauty.com.cn/b29a620d-84d0-42f9-9d9f-332ff7f9ee08?imageslim" alt="">
              <div class="title">在线商城</div>
              <div class="item_info">直播带货 日记案例 品牌资质 <br>
                在线面诊 名医IP 机构动态</div>
            </div>
          </div>
          <img class="img_content1" src="https://resource.51beauty.com.cn/16c4021b-f4cf-47b3-bbd7-008b97babb0a?imageslim" alt="">
        </div>
        <div class="mand flex_column">
          <div class="first_title">管理云</div>
          <div class="custom_solid"></div>
          <div class="last_title">基于数据智能分析的科学管理方案</div>
          <div class="list">
            <div class="item">
              <div class="nav">
                <img class="img1" src="https://resource.51beauty.com.cn/c1deeb89-73a5-49e4-895e-fc5c30c114c7?imageslim" alt="">
                <div>员工管理系统</div>
              </div>
              <div class="ul">
                <div class="li">员工管理</div>
                <div class="li">人物管理</div>
                <div class="li">排版管理</div>
                <div class="li">业绩管理</div>
              </div>
            </div>
            <div class="item">
              <div class="nav">
                <img class="img2" src="https://resource.51beauty.com.cn/84e146af-469e-4cd2-a6e5-cb0e9f6b7c44?imageslim" alt="">
                <div>客户管理系统</div>
              </div>
              <div class="ul">
                <div class="li">客户管理</div>
                <div class="li">标签管理</div>
                <div class="li">客户关系</div>
                <div class="li">会话管理</div>
              </div>
            </div>
            <div class="item">
              <div class="nav">
                <img class="img3" src="https://resource.51beauty.com.cn/bbb1a969-a306-4871-a36f-410208e3d3b1?imageslim" alt="">
                <div>智能看板系统</div>
              </div>
              <div class="ul">
                <div class="li">总业绩数据</div>
                <div class="li">总线索数据</div>
                <div class="li">总推广数据</div>
                <div class="li">总物料数据</div>
              </div>
            </div>
            <div class="item">
              <div class="nav">
                <img class="img4" src="https://resource.51beauty.com.cn/25bd399d-7f52-41e1-8aab-3fca6ad3702f?imageslim" alt="">
                <div>运营管理系统</div>
              </div>
              <div class="ul">
                <div class="li">线上营销活动管理</div>
                <div class="li">线下营销活动管理</div>
                <div class="li">活动数据运营分析</div>
              </div>
            </div>
            <div class="item item4">
              <div class="nav">
                <img class="img3" src="https://resource.51beauty.com.cn/25bd399d-7f52-41e1-8aab-3fca6ad3702f?imageslim" alt="">
                <div>营销服务数据运营系统</div>
              </div>
              <div class="ul flex2">
                <div>
                  <div class="li">活跃分析</div>
                  <div class="li">小程序使用分析</div>
                  <div class="li">模块使用分析</div>
                </div>
                <div class="left3">
                  <div class="li">常用数据运营查询报表</div>
                  <div class="li">产品数据运营分析</div>
                  <div class="li">活动数据运营分析</div>
                </div>
              </div>
            </div>
          </div>
          <img class="img_content2" src="https://resource.51beauty.com.cn/c3618f5b-7d6e-44ac-91bc-5ba78a8967ef?imageslim" alt="">
          <div class="title2 flex_column">
            <div class="title">客户服务</div>
            <div class="custom_solid"></div>
            <div class="cont">全程陪跑 售后无忧</div>
          </div>
          <div class="user_list">
            <div class="item">
              <div class="title">体验期</div>
              <div class="user_list_content">云晰营销官随时答疑 <br>
                专业技术团队一对一指导</div>
            </div>
            <div class="item">
              <div class="title">使用中</div>
              <div class="user_list_content">技术、营销官、运营教练全程陪伴 <br>
                专业营销知识持续输出 <br>
                运营教练指导增长方案落地</div>
            </div>
            <div class="item">
              <div class="title">持续蓄力期</div>
              <div class="user_list_content">收集客户反馈更新产品 <br>
                分享行业前沿内容 <br>
                解答行业发展难题</div>
            </div>
          </div>
        </div>
      </div>
      <div class="call" @click="openAppoint">
        <img src="../assets/fuceng.png" alt="">
      </div>
      <Bottom @openWord="openWord"/>
      <word :wordDialog="wordVisible" @closeWord="closeWord" />
      <appointment :visibleDialog="appointmentVisible" @closeAppoint="closeAppoint" />
    </div>
  </div>
</template>
<script>
  import NavBar from '@/components/navBar.vue'
  import Bottom from '@/components/bottom.vue'
  import Appointment from '@/components/appointment.vue'
  import Word from '@/components/word.vue'

  export default {
    name: 'my',
    components: {
      NavBar,
      Bottom,
      Appointment,
      Word
    },
    data() {
      return {
        status: [],
        type: [],
        wordVisible: false,
        appointmentVisible: false // 预约弹层是否显示
      }
    },
    mounted() {
      // this.getData()
    },
    methods: {
      getData(){
        this.$api.post('/bobo-admin/interface/order/count', {}, response => {
          const data = response.data.status
          const type = response.data.type
          data.map(item=>{
            item.percent = Number(item.percent)
          })
          type.map(item=>{
            item.percent = Number(item.percent)
          })
          this.status = data
          this.type = type
          this.getStatus(data)
          this.getType(type)
        });
      },
      closeWord (e) {
        this.wordVisible = e
      },
      openWord () {
        this.wordVisible = true
      },
      // 关闭预约弹层
      closeAppoint (e) {
        this.appointmentVisible = e
      },
      // 打开预约弹层
      openAppoint () {
        this.appointmentVisible = true
      },
    },

  }

</script>
<style scoped lang="less">
  .home {
    padding-top: 0px;
    position: relative;
    .call{
      position: fixed;
      bottom: 50px;
      left: 335px;
      img{
        width: 30px;
        height: 30px;
      }
    }
    .flex_column{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .flex{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .content{
      display: flex;
      align-items: center;
      flex-direction: column;
      .banner{
        width: 375px;
        height: 107px;
        background: url('../assets/home-banner.png') no-repeat;
        background-size: 100% 100%;
        .title{
          font-size: 9px;
          font-weight: 500;
          color: #FFFFFF;
          text-align: center;
        }
        .last_title{
          height: 4px;
          font-size: 4px;
          font-weight: 400;
          color: #FFFFFF;
          margin-top: 8px;
        }
        .banner_btn{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 3px 5px 3px 9px;
          background: #fff;
          border-radius: 2px;
          margin-top: 17px;
          cursor: pointer;
          .name{
            font-size: 4px;
            font-weight: bold;
            color: #257FFF;
            margin-top: 0.2px;
          }
          .img{
            width: 4px;
            height: 4px;
            display: block;
            margin-left: 4px;
          }
        }
      }
      .info{
        box-sizing: border-box;
        width: 375px;
        padding: 0 50px;
        .first_title{
          font-size: 7px;
          font-weight: bold;
          color: #333333;
          margin-top: 23px;
        }
        .title_icon{
          width: 13px;
          height: 1px;
          background: url("../assets/title_icon.png") no-repeat;
          background-size: 100% 100%;
          margin-top: 5px;
        }
        .last_title{
          font-size: 4px;
          font-weight: 400;
          color: #414141;
          margin-top: 5px;
        }
        .list{
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .item{
            .img{
              width: 37px;
              height: 37px;
              display: block;
            }
            .name{
              font-size: 6px;
              font-weight: 400;
              color: #333333;
              margin-top: 5px;
              width: 37px;
              text-align: center;
            }
          }
        }
        .info_nav{
          padding: 0 2px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 8px;
          >div{
            height: 22px;
          }
          p{
            font-size: 3px;
            font-weight: 400;
            color: #666666;
            margin-top: 3px;
            text-align: center;
            line-height: 4px;
          }
          .left{
            width: 68px;
            div{
              background: url("https://resource.51beauty.com.cn/d6c65f18-eec6-41f7-b42d-976074d43d73?imageslim") no-repeat;
              background-size: 100% 100%;
              height: 10px;
              font-size: 4px;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
          .center{
            width: 100px;
            div{
              background: url("https://resource.51beauty.com.cn/9bd185cd-7796-4686-926f-ed4b82568fd9?imageslim") no-repeat;
              background-size: 100% 100%;
              height: 10px;
              font-size: 4px;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
          .right{
            width: 52px;
            div{
              background: url("https://resource.51beauty.com.cn/c390226b-a545-4e89-af16-c86b4ffc3dbd?imageslim") no-repeat;
              background-size: 100% 100%;
              height: 10px;
              font-size: 4px;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
        }
        .info_content{
          width: 375px;
          padding: 0 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 2px;
          .solid{
            width: 22px;
            .val{
              font-size: 3px;
              font-weight: 400;
              color: #414141;
              text-align: center;
              line-height: 4px;
            }
            .solid_icon{
              width: 22px;
              height: 2px;
              display: block;
            }
          }
          .one_img{
            width: 72px;
            height: 91px;
            display: block;
          }
          .two_img{
            width: 104px;
            height: 91px;
            display: block;
          }
          .three_img{
            width: 56px;
            height: 91px;
            display: block;
          }
        }
        .content_btn{
          width: 70px;
          height: 14px;
          background: url("https://resource.51beauty.com.cn/9cb63ccc-614e-43f0-9b7e-2e6215a9fdd8?imageslim") no-repeat;
          background-size: 100% 100%;
          margin-top: 8px;
          cursor: pointer;
          >div{
            font-size: 6px;
            font-weight: 400;
            color: #FFFFFF;
          }
          img{
            width: 5px;
            height: 5px;
            display: block;
            margin-left: 10px;
          }
        }
      }
      .custom{
        margin-top: 16px;
        width: 375px;
        background: url("https://resource.51beauty.com.cn/67666a8f-7bff-4b48-a8d2-e8f65e325ed5?imageslim") no-repeat;
        background-size: 375px 98px;
        .first_title{
          font-size: 7px;
          font-weight: bold;
          color: #FFFFFF;
          margin-top: 21px;
        }
        .custom_solid{
          width: 13px;
          height: 1px;
          background: url("https://resource.51beauty.com.cn/a4375959-ac43-4788-a103-87901403e05d?imageslim") no-repeat;
          background-size: 100% 100%;
          margin-top: 5px;
        }
        .last_title{
          font-size: 4px;
          font-weight: 400;
          color: #FFFFFF;
          margin-top: 5px;
        }
        .list{
          margin-top: 16px;
          width: 375px;
          height: 70px;
          padding: 0 50px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item{
            width: 50px;
            height: 70px;
            background: #fff;
            box-shadow: 0px 0px 3px 0px rgba(144, 156, 173, 0.19);
            padding: 7px;
            box-sizing: border-box;
            border-radius: 1px;
            justify-content: flex-start;
            img{
              width: 18px;
              height: 18px;
              display: block;
            }
            .name{
              font-size: 4px;
              font-weight: 400;
              color: #2278F0;
              margin-top: 4px;
            }
            .infos{
              text-align: justify;
              font-size: 4px;
              font-weight: 400;
              color: #666666;
              line-height: 6px;
              margin-top: 8px;
            }
          }
        }
        .custom_content{
          margin-top: 16px;
          display: flex;
          align-items: center;
          .left{
            img{
              width: 85px;
              height: 60px;
              display: block;
            }
            .title{
              margin-top: 3px;
              font-size: 4px;
              font-weight: 400;
              color: #333333;
              text-align: center;
              line-height: 6px;
            }
          }
          .right{
            margin-left: 15px;
            display: flex;
            align-items: center;
            .li{
              width: 45px;
              display: flex;
              align-items: center;
              flex-direction: column;
              .li_img{
                width: 23px;
                height: 20px;
                display: block;
              }
              .title{
                font-size: 4px;
                font-weight: 500;
                color: #333333;
                margin-top: 5px;
              }
              .cont{
                text-align: justify;
                margin-top: 7px;
                font-size: 4px;
                font-weight: 400;
                color: #666666;
                line-height: 6px;
              }
            }
            .right_solid{
              height: 29px;
              width: 0.3px;
              background: rgba(102, 102, 102, 0.2);
              margin: 0 6px;
            }
          }
        }
      }
      .cloud{
        margin-top: 23px;
        width: 375px;
        background: url("https://resource.51beauty.com.cn/2dbcd0d2-d6dd-4fe5-8114-637f68516c8e?imageslim") no-repeat;
        background-size: 375px 131px;
        .first_title{
          font-size: 7px;
          font-weight: bold;
          color: #FFFFFF;
          margin-top: 21px;
        }
        .custom_solid{
          width: 13px;
          height: 1px;
          background: url("https://resource.51beauty.com.cn/a4375959-ac43-4788-a103-87901403e05d?imageslim") no-repeat;
          background-size: 100% 100%;
          margin-top: 5px;
        }
        .last_title{
          font-size: 4px;
          font-weight: 400;
          color: #FFFFFF;
          margin-top: 5px;
        }
        .list{
          margin-top: 13px;
          width: 375px;
          height: 40px;
          padding: 0 50px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .img1{
              width: 9px;
              height: 11px;
              display: block;
            }
            img{
              width: 12px;
              height: 12px;
              display: block;
            }
            .title{
              font-size: 3px;
              font-weight: 500;
              color: #FFFFFF;
              margin-top: 5px;
            }
            .item_info{
              text-align: center;
              font-size: 3px;
              font-weight: 400;
              color: #FFFFFF;
              margin-top: 5px;
              line-height: 6px;
            }
          }
        }
        .img_content1{
          margin-top: 10px;
          width: 242px;
          height: 125px;
          display: block;
        }
      }
      .mand{
        margin-top: 19px;
        margin-bottom: 25px;
        width: 375px;
        .first_title{
          font-size: 7px;
          font-weight: bold;
          color: #333;
        }
        .custom_solid{
          width: 13px;
          height: 1px;
          background: url("https://resource.51beauty.com.cn/c4128500-bc5e-4677-bd79-c881d3358bb9?imageslim") no-repeat;
          background-size: 100% 100%;
          margin-top: 5px;
        }
        .last_title{
          font-size: 4px;
          font-weight: 400;
          color: #414141;
          margin-top: 5px;
        }
        .list{
          margin-top: 13px;
          width: 375px;
          height: 48px;
          padding: 0 50px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item{
            width: 43px;
            height: 51px;
            background: #FFFFFF;
            box-shadow: 0px 0px 3px 0px rgba(144, 156, 173, 0.19);
            border-radius: 1px;
            padding: 5px;
            box-sizing: border-box;
            .nav{
              display: flex;
              align-items: center;
              justify-content: center;
              .img1{
                width: 7px;
                height: 5px;
                display: block;
              }
              .img2{
                width: 5px;
                height: 6px;
                display: block;
              }
              .img3{
                width: 5px;
                height: 5px;
                display: block;
              }
              .img4{
                width: 6px;
                height: 6px;
                display: block;
              }
              div{
                font-size: 4px;
                font-weight: 400;
                color: #414141;
                margin-left: 2px;
                margin-top: 0.3px;
                line-height: 6px;
              }
            }
            .ul{
              display: flex;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;
              margin-top: 2px;
              .li{
                padding-left: 4px;
                position: relative;
                font-size: 3.5px;
                font-weight: 400;
                color: #414141;
                margin-top: 3px;
              }
              .li:after{
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto 0;
                content: '';
                display: block;
                width: 2px;
                height: 2px;
                background: #3784F2;
                border-radius: 2px;
              }
            }
          }
          .item4{
            width: 88px;
            .flex2{
              display: flex;
              flex-wrap: wrap;
            }
            .left3{
              margin-left: 3px;
            }
          }
        }
        .img_content2{
          margin-top: 10px;
          width: 230px;
          height: 72px;
          display: block;
        }
        .title2{
          width: 52px;
          height: 54px;
          background: url("https://resource.51beauty.com.cn/0a76b1a0-dfd6-455f-9983-f37942796a04?imageslim") no-repeat;
          background-size: 100% 100%;
          margin-top: 14px;
          .title{
            font-size: 7px;
            font-weight: bold;
            color: #333333;
          }
          .custom_solid{
            width: 13px;
            height: 1px;
            background: url("https://resource.51beauty.com.cn/c4128500-bc5e-4677-bd79-c881d3358bb9?imageslim") no-repeat;
            background-size: 100% 100%;
            margin-top: 5px;
          }
          .cont{
            font-size: 4px;
            font-weight: 400;
            color: #414141;
            margin-top: 5px;
          }
        }
        .user_list{
          margin-top: 5px;
          width: 275px;
          height: 50px;
          background: url("https://resource.51beauty.com.cn/ee4032dd-1a84-4bc1-b0fe-24ad1dd47a6f?imageslim") no-repeat;
          background-size: 100% 100%;
          display: flex;
          .item{
            width: 91.6px;
            height: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title{
              background: #FFFFFF;
              border-radius: 4px 4px 4px 0px;
              padding: 3px 6px;
              font-size: 4px;
              font-weight: 500;
              color: #333333;
              margin-top: 11px;
            }
            .user_list_content{
              text-align: center;
              margin-top: 6px;
              line-height: 5.5px;
              font-size: 4px;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
</style>
