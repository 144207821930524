var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advertising"},[(_vm.scrollTop==0)?_c('NavBar1',{attrs:{"type":"product"}}):_vm._e(),(_vm.scrollTop>0)?_c('NavBar2',{attrs:{"type":"product"}}):_vm._e(),_c('div',{staticClass:"banner"},[_c('div',{staticClass:"title"},[_vm._v("企微SCRM")]),_c('div',{staticClass:"subtitle"},[_vm._v("基于行业的公私域一体化运营")]),_c('div',{staticClass:"btn",on:{"click":_vm.openAppoint}},[_vm._v("预约体验")])]),_vm._m(0),_c('div',{staticClass:"exhibition"},[_c('div',{staticClass:"exhibition_con"},[_c('div',[_vm._v("新闻与活动")]),_c('div',{staticClass:"wow slideInUp"},[_c('div',{staticClass:"exhibition_item",on:{"click":function($event){return _vm.article('https://cloud.tencent.com/developer/article/1892234?from=article.detail.1902177')}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"exhibition_item",on:{"click":function($event){return _vm.article('https://cloud.tencent.com/document/product/306/47166')}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"exhibition_item",on:{"click":function($event){return _vm.article('https://baijiahao.baidu.com/s?id=1718001203675989497&wfr=spider&for=pc')}}},[_vm._m(5),_vm._m(6)])])])]),_c('Bottom'),_c('appointment',{attrs:{"visibleDialog":_vm.appointmentVisible},on:{"closeAppoint":_vm.closeAppoint}}),_c('Contact')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"wow slideInUp"},[_c('div',[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/75cd6f5f-0b2f-4f42-858a-a3900393a2a2?imageslim","alt":""}})]),_c('div',[_c('div',[_vm._v("全渠道获客及智能分配")]),_c('div',[_vm._v("全渠道引流获客，包括线上广告、私域直播、分享裂变、官网媒体、线下活动等")])])]),_c('div',{staticStyle:{"background":"#FAFBFD"}},[_c('div',{staticClass:"wow slideInUp"},[_c('div',[_vm._v("符合行业SOP操作流程")]),_c('div',[_vm._v("基于对消费医疗深度理解为提供标准化行业SOP，帮助咨询人员提升沟通效率和转化率")])]),_c('div',{staticClass:"wow slideInUp"},[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/662223ee-637c-46f6-8ffd-183c36744796?imageslim","alt":""}})]),_c('p',[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/aca774d8-2132-4dbc-8e6a-f96680bfb0d4?imageslim","alt":""}})])]),_c('div',{staticClass:"wow slideInUp"},[_c('div',[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/eaef3e09-f59f-4e24-be72-e605bae800b0?imageslim","alt":""}})]),_c('div',[_c('div',[_vm._v("基于标签营销自动化")]),_c('div',[_vm._v("售前及时跟进提醒、售中内容转化引导到院、售后智能回访等")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_img"},[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/f17ab896-e595-4a3e-bda8-7258a73e1e48?imageslim","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_text"},[_c('div',{staticClass:"title"},[_vm._v("腾讯SaaS加速器三期总决选")]),_c('div',{staticClass:"subtitle"},[_vm._v("云晰科技从1100家报名SaaS企业中顺利突围，获得最终角逐Top 40席位的入场券。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_img"},[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/a5826992-7c89-4adf-bbca-c986ca84e718?imageslim","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_text"},[_c('div',{staticClass:"title"},[_vm._v("云晰科技已通过腾讯云千帆计划复选")]),_c('div',{staticClass:"subtitle"},[_vm._v("营销云产品（医美版、口腔版、眼科版）已上架腾讯云市场，成为“千帆甄选”推荐产品。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_img"},[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/4bc47af4-78d0-4a64-95b3-be41e94b212e?imageslim","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_text"},[_c('div',{staticClass:"title"},[_vm._v("云晰科技和企业微信官方合作组织沙龙会")]),_c('div',{staticClass:"subtitle"},[_vm._v("云晰与企业微信医疗板块深度合作共创，将企微作为腾讯新流量生态的结合点，帮助消费医疗…")])])
}]

export { render, staticRenderFns }