<template>
    <div>
        <div class="content">
            <div @click="openAppoint">
                <img src="https://resource.51beauty.com.cn/bcdd86d2-9e51-4d9e-a9e0-459291f8c385?imageslim" alt="">立即咨询
            </div>
            <div @click="showCode">
                <img src="https://resource.51beauty.com.cn/8a65b62b-c978-480b-a08b-5d94b2d2d244?imageslim" alt="">扫码联系
            </div>
        </div>
        <Appointment :visibleDialog="appointmentVisible" @closeAppoint="closeAppoint" />
        <div class="code" :class="code?'show':''">
            <div class="code_con">
                <img src="https://resource.51beauty.com.cn/5926e836-adb5-46c1-b3fe-927c645acf04?imageslim" alt="">
            </div>
            <div class="code_clone" @click="hideCode">
                <img src="https://resource.51beauty.com.cn/f347113f-b90f-45f5-b46e-6f86fb7725e2?imageslim" alt="">
            </div>
        </div>
    </div>

</template>
<script>import Appointment from '@/components/appointment.vue'
export default {
    name: 'contact',
    components: {
        Appointment
    },
    props: {

    },
    data () {
        return {
            appointmentVisible: false,
            code:false
        }
    },
    mounted() {
    },
    methods: {
        close () {
            this.$emit('closeWord', false)
        },
        // 关闭预约弹层
        closeAppoint (e) {
            this.appointmentVisible = e
        },
        // 打开预约弹层
        openAppoint () {
            this.appointmentVisible = true
        },
        showCode(){
            this.code = true
        },
        hideCode(){
            this.code = false
        },
    }
}
</script>
<style lang="less" scoped>
    .code{
        background: url("https://resource.51beauty.com.cn/77158b1c-a7a2-4d76-bf0b-173dd56958a8?imageslim") no-repeat;
        background-size: cover;
        height: 43px;
        width: 38px;
        position: fixed;
        right: -40px;
        transition: 1s;
        top: 117px;
        box-shadow: 0px 2px 5px 0px rgba(55,99,170,0.1);
        z-index: 111;
        >.code_con{
            width: 28px;
            height: 28px;
            margin: 0 auto;
            margin-top: 2px;
            img{
                width: 100%;
                box-shadow: 0px 2px 5px 0px rgba(55,99,170,0.1);
                display: block;
            }
        }
        >.code_clone{
            width: 5px;
            height: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            background: #fff;
            border-radius: 50%;
            right: 1px;
            top: -3px;
            cursor: pointer;
            img{
                width: 3px;
                height: 3px;
                display: block;
            }
        }
    }
    .show{
        right: 0px;
    }
    .content{
        position: fixed;
        right: 0;
        top: 130px;
        width: 27px;
        height: 20px;
        cursor: pointer;
        z-index: 111;
        >div{
            display: flex;
            align-items: center;
            font-size: 3px;

        }
        >div:nth-child(1){
            width: 27px;
            height: 10px;
            background: #2C68FF;
            color: #fff;
            border-radius: 1px 0 0 0;
        }
        >div:nth-child(2){
            width: 27px;
            height: 10px;
            background: #FFFFFF;
            box-shadow: 0px 1px 3px 0px rgba(187,187,187,0.2);
            color: #273755;
            border-radius: 0 0 0 1px;
        }
        img{
            width: 5px;
            height: 5px;
            display: block;
            margin: 0 1px 0 5px;
        }
    }
</style>
