<template>
    <div>
        <a-modal
            :visible="wordDialog"
            :footer="null"
            :layout="formLayout"
            :closable="false"
            :maskClosable="true"
            @cancel="close">
            <div class="word">
                <img src="https://resource.51beauty.com.cn/735597e2-04e7-4324-a56c-f7b9239c5d13?imageslim" alt="">
            </div>
        </a-modal>
    </div>
</template>
<script>

export default {
    name: 'word',
    props: {
        wordDialog: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            formLayout: 'horizontal',
        }
    },
    mounted() {
    },
    methods: {
        close () {
            this.$emit('closeWord', false)
        }
    }
}
</script>
<style lang="less" scoped>
    .word{
        img{
            width: 100%;
        }
        .ant-modal-body{
            padding: 0;
        }
    }
</style>

