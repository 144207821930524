<template>
  <div class="advertising">
    <NavBar1 type="introduce" />
<!--    <NavBar2 v-if="scrollTop>0" type="product" />-->
    <div class="banner">

    </div>
    <div class="content">
      <img class="wow slideInUp" src="https://resource.51beauty.com.cn/f86d3ea6-6325-495e-9806-0742ecfdc231?imageslim" alt="">
      <img class="wow slideInUp" src="https://resource.51beauty.com.cn/01c7619c-834f-4ec4-8e12-a6f27a0ba0fd?imageslim" alt="">
      <img class="wow slideInUp" src="https://resource.51beauty.com.cn/7a1e6cf3-e1c0-4d8e-8f48-5e2665420342?imageslim" alt="">
      <img class="wow slideInUp" src="https://resource.51beauty.com.cn/8b5de164-c206-4429-bb73-399fc000a99f?imageslim" alt="">
      <img class="wow slideInUp" src="https://resource.51beauty.com.cn/fcf339d6-cecc-4097-a7b4-e7006110fb51?imageslim" alt="">
    </div>
    <!-- 专注消费医疗 -->
    <Bottom />
    <appointment
            :visibleDialog="appointmentVisible"
            @closeAppoint="closeAppoint"
    />
    <Contact />
  </div>
</template>
<script>
  import NavBar1 from "@/components/navBarProduct.vue";
  // import NavBar2 from "@/components/navBar.vue";
  import Bottom from "@/components/bottom.vue";
  import Appointment from "@/components/appointment.vue";
  import Contact from '@/components/contact.vue'

  import { WOW } from 'wowjs'
  export default {
    components: {
      NavBar1,
      // NavBar2,
      Bottom,
      Appointment,
      Contact
    },
    data() {
      return {
        scrollTop:0,
        appointmentVisible:false
      };
    },
    mounted() {
      new WOW().init()
      window.addEventListener('scroll',this.handleScroll,true)
    },
    methods: {
      // 关闭预约弹层
      closeAppoint (e) {
        this.appointmentVisible = e
      },
      // 打开预约弹层
      openAppoint () {
        this.appointmentVisible = true
      },
      handleScroll(){
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        this.scrollTop = scrollTop
      },
      article(href){
        window.location = href
      }
    },
  };
</script>
<style scoped lang="less">
  .advertising{
    .banner{
      background: url("https://resource.51beauty.com.cn/8ca648e1-7cf7-4bb9-9bdb-ffffd8a71e75?imageslim") no-repeat;
      background-size: cover;
      width: 375px;
      height: 94px;
      .title{
        font-size: 10px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        padding-top: 27px;
      }
      .subtitle{
        font-size: 6px;
        color: #fff;
        margin-top: 3px;
        text-align: center;
      }
      .btn{
        width: 33px;
        height: 10px;
        background: #FFFFFF;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
        border-radius: 1px;
        color: #2C68FF;
        cursor:pointer;
        font-size: 4px;
        text-align: center;
        line-height: 10px;
        margin: 0 auto;
        margin-top: 16px;
      }
    }
    .content{
      >img{
        width: 100%;
        display: block;
      }
    }
  }
</style>