var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advertising"},[_c('NavBar1',{attrs:{"type":"product"}}),_c('div',{staticClass:"banner"},[_c('div',{staticClass:"title"},[_vm._v("私域直播")]),_c('div',{staticClass:"subtitle"},[_vm._v("满足医疗行业专业性要求，私域直播带货加私域直播科普一体化")]),_c('div',{staticClass:"btn",on:{"click":_vm.openAppoint}},[_vm._v("预约体验")])]),_vm._m(0),_c('div',{staticClass:"exhibition"},[_c('div',{staticClass:"exhibition_con"},[_c('div',[_vm._v("新闻与活动")]),_c('div',{staticClass:"wow slideInUp"},[_c('div',{staticClass:"exhibition_item",on:{"click":function($event){return _vm.article('https://cloud.tencent.com/developer/article/1892234?from=article.detail.1902177')}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"exhibition_item",on:{"click":function($event){return _vm.article('https://cloud.tencent.com/document/product/306/47166')}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"exhibition_item",on:{"click":function($event){return _vm.article('https://baijiahao.baidu.com/s?id=1718001203675989497&wfr=spider&for=pc')}}},[_vm._m(5),_vm._m(6)])])])]),_c('Bottom'),_c('appointment',{attrs:{"visibleDialog":_vm.appointmentVisible},on:{"closeAppoint":_vm.closeAppoint}}),_c('Contact')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"wow slideInUp"},[_c('div',[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/6974ec47-7dd9-456b-aafc-c379ccadf70a?imageslim","alt":""}})]),_c('div',[_c('div',[_vm._v("私域直播业绩增长")]),_c('div',[_vm._v("云晰为客户提供完整的一整套直播服务，包括直播科技软件产品（云晰有直播牌照）、策划、社群运营、设备、主持人、导演、场控等全部都是由云晰提供的服务，快速帮助客户做老客户激活、新客户裂变成交，做业绩增长")])])]),_c('div',{staticStyle:{"background":"#FAFBFD"}},[_c('div',{staticClass:"wow slideInUp"},[_c('div',[_vm._v("私域直播科普宣教")]),_c('div',[_vm._v("客户使用的云晰直播产品，可以最大化降低公域平台监管的前提下针对不同单病种做科普直播，加强对患者的教育，且可以做到日常化直播")])]),_c('div',{staticClass:"wow slideInUp"},[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/acaba8ce-5526-4df0-bb3e-155ad8865c94?imageslim","alt":""}})]),_c('p',[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/aca774d8-2132-4dbc-8e6a-f96680bfb0d4?imageslim","alt":""}})])]),_c('div',{staticClass:"wow slideInUp"},[_c('div',[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/5cd5802e-9593-4a6c-83ae-717a1b017b7e?imageslim","alt":""}})]),_c('div',[_c('div',[_vm._v("私域直播最佳实践")]),_c('div',[_vm._v("服务了众多世界500强、医疗上市公司、区域特色医疗品牌，包括口腔、眼科、中医、医美、慢病管理、高端辅助生殖行业，几百万到上千万业绩不等，广受好评.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_img"},[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/f17ab896-e595-4a3e-bda8-7258a73e1e48?imageslim","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_text"},[_c('div',{staticClass:"title"},[_vm._v("腾讯SaaS加速器三期总决选")]),_c('div',{staticClass:"subtitle"},[_vm._v("云晰科技从1100家报名SaaS企业中顺利突围，获得最终角逐Top 40席位的入场券。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_img"},[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/a5826992-7c89-4adf-bbca-c986ca84e718?imageslim","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_text"},[_c('div',{staticClass:"title"},[_vm._v("云晰科技已通过腾讯云千帆计划复选")]),_c('div',{staticClass:"subtitle"},[_vm._v("营销云产品（医美版、口腔版、眼科版）已上架腾讯云市场，成为“千帆甄选”推荐产品。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_img"},[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/4bc47af4-78d0-4a64-95b3-be41e94b212e?imageslim","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_text"},[_c('div',{staticClass:"title"},[_vm._v("云晰科技和企业微信官方合作组织沙龙会")]),_c('div',{staticClass:"subtitle"},[_vm._v("云晰与企业微信医疗板块深度合作共创，将企微作为腾讯新流量生态的结合点，帮助消费医疗…")])])
}]

export { render, staticRenderFns }