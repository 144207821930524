<template>
    <div>
        <div :class="scrollTop>0?'navbar bg_color':'navbar'">
            <div class="logo_list" @click="skip('/')">
                <img v-if="scrollTop==0" class="logo" src="../assets/logo.png" alt="">
                <img v-if="scrollTop>0" class="logo" src="../assets/logo3.png" alt="">
                <img v-if="scrollTop==0" class="logo2" src="../assets/logo2.png" alt="">
                <img v-if="scrollTop>0" class="logo2" src="../assets/logo4.png" alt="">
            </div>
            <div class="nav_content">
                <div  :class="item.activity ? 'activity list' : 'list'" v-for="(item,index) in List" :key="item.id">
                    <div class="name" @click="skip(item.to)">{{item.name}}</div>
                    <div class="line" :class="item.activity?'line_act':''"></div>
                    <div v-if="index==1" class="subset_list">
                        <div v-for="(items,indexs) in item.subset" :key="indexs" @click="skip(items.path)"><img :src="items.icon" alt=""/>{{items.name}}</div>
                    </div>
                    <div v-if="index==2" class="subset_list1-box">
                      <div class="subset_list1">
                        <div v-for="(items,indexs) in item.subset" :key="indexs" @click="skip(items.path)"><img :src="items.icon" alt=""/>{{items.name}}</div>
                      </div>
                    </div>
                </div>
                <div class="nav_btn" @click="openAppoint">立即体验</div>
            </div>
        </div>
        <Appointment :visibleDialog="appointmentVisible" @closeAppoint="closeAppoint" />
    </div>
</template>

<script>
import Appointment from '@/components/appointment.vue'

export default {
    name: 'NavBar',
    components: {
        Appointment
    },
    props: {
        type: String
    },
    data() {
        return {
            List: [
                {
                    id:1,
                    name:'云晰首页',
                    activity: this.type === 'home',
                    to:'/'
                },
                {
                    id:2,
                    name:'我的产品',
                    activity: this.type === 'product',
                    to:'/advertising',
                    subset:[
                      {name:'私域直播',icon:'https://resource.51beauty.com.cn/96f4d5da-873b-4577-8602-c14179ecc4d8?imageslim',path:'/live'},
                      {name:'企微SCRM',icon:'https://resource.51beauty.com.cn/10e34808-ee11-4b1d-b42d-40f1e4a1ddc2?imageslim',path:'/scrm'},
                      {name:'院内管理',icon:'https://resource.51beauty.com.cn/10622a46-c752-43f9-8ae8-7db6a75e8eb2?imageslim',path:'/management'},
                      {name:'单病种数字化服务',icon:'https://resource.51beauty.com.cn/b58e358f-e551-42f3-8682-8ab0675645d4?imageslim',path:'/digitize'},
                      {name:'会员管理体系',icon:'https://resource.51beauty.com.cn/d65b900b-48ec-4a48-a08d-7ba201a7cc50?imageslim',path:'/member'},
                      {name:'流量获客',icon:'https://resource.51beauty.com.cn/aaddfe73-7017-4b4e-991a-127be7ebe7e8?imageslim',path:'/advertising'}
                    ]
                },
                {
                    id:3,
                    name:'解决方案',
                    activity: this.type === 'plan',
                    to:'/beauty',
                    subset:[
                      {name:'口腔行业',icon:'https://resource.51beauty.com.cn/5f321ae2-f28e-4700-9892-bc7a68a9944b?imageslim',path:'/oral'},
                      {name:'眼科行业',icon:'https://resource.51beauty.com.cn/65efe3d7-6690-4474-8c12-fffc184b6a95?imageslim',path:'/eye'},
                      {name:'中医行业',icon:'https://resource.51beauty.com.cn/e7cbc4d3-e5aa-4f61-85f3-373aad7161b3?imageslim',path:'/traditional'},
                      {name:'慢病管理',icon:'https://resource.51beauty.com.cn/bf6c2294-fe12-4ea3-8727-516e51d5725d?imageslim',path:'/chronic'},
                      {name:'肿瘤行业',icon:'https://resource.51beauty.com.cn/4695fce0-fbef-472a-abf2-81ff973ae74e?imageslim',path:'/tumour'},
                      {name:'高端辅助生殖',icon:'https://resource.51beauty.com.cn/7b06e378-a2ab-4635-9b54-8e607386aac7?imageslim',path:'/reproduce'},
                      {name:'医美行业',icon:'https://resource.51beauty.com.cn/84e304a1-7366-46e7-867f-e90270c4f36d?imageslim',path:'/beauty'},
                      {name:'植发行业',icon:'https://resource.51beauty.com.cn/694cca72-b5d8-427f-a11f-195aadee7001?imageslim',path:'/hair'},
                    ]
                },
              {
                id:4,
                name:'关于我们',
                activity: this.type === 'introduce',
                to:'/introduce'
              },
            ],
            appointmentVisible: false, // 预约弹层是否显示
            scrollTop: 0
        }
    },
    mounted() {
        window.addEventListener('scroll',this.handleScroll,true)
    },
    methods: {
        // 关闭预约弹层
        closeAppoint (e) {
            this.appointmentVisible = e
        },
        // 打开预约弹层
        openAppoint () {
            this.appointmentVisible = true
        },
        handleScroll(){
            let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            this.scrollTop = scrollTop
        },
        skip(path){
            this.$router.push(path)
        }
    }
}
</script>
<style scoped lang="less">
    .bg_color{
        background: #2C68FF !important;
        color: #fff !important;
        .name{
            color: #fff !important;
        }
        .line{
            background: #fff !important;
        }
        .name:hover{
            color: #fff !important;
        }
        .nav_btn{
            background: #fff !important;
            color: #2C68FF !important;
        }
    }
    .navbar {
        height: 13px;
        width: 375px;
        position: fixed;
        top: 0;
        z-index: 99999;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 70px;
        transition: all .3s ease-in-out;
        padding-top: 2px;
        .logo_list{
            display: flex;
            align-items: center;
            .logo{
                width: 10px;
                display: block;
                margin-top: -2px;
            }
            .logo2{
                width: 23px;
                height: 6px;
                display: block;
                margin-left: 4px;
                margin-top: -2px;
            }
        }
        .nav_content{
            display: flex;
            justify-content: space-around;
            align-items: center;
            .list{
                padding: 0 6px;
                height: 10px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;
                cursor:pointer;
                .subset_list{
                    position: absolute;
                    top: -20px;
                    background: #FFFFFF;
                    box-shadow: 0px 1px 2px 0px rgba(55,99,170,0.1);
                    border-radius: 1px;
                    width: 36px;
                    padding: 3px 0;
                    opacity: 0;
                    transition: .3s;
                    display: none;
                    >div{
                        padding: 2px 0;
                        display: flex;
                        align-items: center;
                        color: #273755;
                        font-size: 3px;
                        img{
                            width: 4px;
                            height: 4px;
                            display: block;
                            margin-right: 2px;
                            margin-left: 3.5px;
                        }
                    }
                    >div:hover{
                        color: #2C68FF;
                    }
                }
              .subset_list1-box{
                display: none;
                position: absolute;
                top: -100px;
                opacity: 0;
              }
              .subset_list1{
                background: #FFFFFF;
                box-shadow: 0px 1px 2px 0px rgba(55,99,170,0.1);
                border-radius: 1px;
                width: 60px;
                padding: 3px 0;
                transition: .3s;
                display: flex;
                flex-wrap: wrap;
                >div{
                  padding: 2px 0;
                  display: flex;
                  width: 50%;
                  align-items: center;
                  color: #273755;
                  font-size: 3px;
                  img{
                    width: 4px;
                    height: 4px;
                    display: block;
                    margin-right: 2px;
                    margin-left: 3.5px;
                  }
                }
                >div:hover{
                  color: #2C68FF;
                }
              }
                .name{
                    font-size: 3px;
                    color: #333;
                    display: block;
                    font-weight: 500;
                    transition: .3s;
                }

                .line{
                    background: #2C68FF;
                    width: 0px;
                    height: .5px;
                    margin-top: 1px;
                    transition: .6s;
                }
            }
            .list:hover .name{
                color: #2C68FF;
            }
            .line_act{
                opacity: 1;
                width: 7px !important;
            }
            .list:hover .line{
                opacity: 1 !important;
                width: 7px;
            }
            .list:hover .subset_list{
                opacity: 1;
                top: 10px;
                display: block;
            }
          .list:hover .subset_list1-box{
            display: block;
            opacity: 1;
            top: 10px;
          }
            /*.list .subset_list:hover{*/
            /*    opacity: 0 !important;*/
            /*}*/
            .list.activity .name{
                color: #2C68FF;
            }
            .nav_btn{
                margin-left: 7px;
                cursor: pointer;
                width: 16px;
                height: 6px;
                border-radius: 1px;
                background: #2C68FF;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-weight: 500;
                font-size: 2.5px;
                margin-top: -2px;
                transition: .3s;
            }
        }
    }
</style>
