<template>
    <div class="hello">
<!--        <a href="https://beian.miit.gov.cn/#/Integrated/index">copyright 2019 北京云晰科技有限公司 备案号： 京ICP备18052470号</a>-->
<!--        <div @click="open2">关于云晰</div>-->
        <div class="content">
            <div class="content_left wow slideInUp">
                <div class="text1">
                    <div>关于我们</div>
                    <div @click="skip('/advertising')">产品介绍</div>
                    <div @click="skip('/oral')">解决方案</div>
                    <div>
                        <a href="https://www.zhipin.com/gongsir/2ee6f9ac9f41b77b1Xx82Nu9GQ~~.html?ka=company-jobs">加入我们</a>
                    </div>
                </div>
                <div class="text2">
                    <div>联系我们</div>
                    <div>市场邮箱：market@xitech.com.cn</div>
                    <div>联系电话：13661642616</div>
                    <div>公司地址：北京市朝阳区百子湾东朝时代西区109室</div>
                </div>
            </div>
            <div class="content_right wow slideInUp">
                <div>
                    <div>关注我们</div>
                    <div>
                        <img src="https://resource.51beauty.com.cn/97656a55-1675-4750-b488-f7cf662d69f8?imageslim" alt="">
                    </div>
                </div>
                <div>
                    <div>联系我们</div>
                    <div>
                        <img src="https://resource.51beauty.com.cn/5926e836-adb5-46c1-b3fe-927c645acf04?imageslim" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="for_the_record wow slideInUp" @click="record">Copyright ©北京云晰科技有限公司 备案号：京ICP备18052470号</div>
    </div>
</template>

<script>
    export default {
        name: 'Appointment',
        props: {
            type: String
        },
        data() {
            return {}
        },
        mounted() {
        },
        methods: {
            record () {
                // this.$emit('openWord')
                window.open("https://beian.miit.gov.cn/#/Integrated/index");
            },
            skip(path){
                this.$router.push(path)
            },
        }
    }
</script>
<style scoped lang="less">
    .hello {
        height: 78px;
        width: 375px;
        background: #373737;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        flex-direction: column;
        .content{
            width: 228px;
            margin: 0 auto;
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
            .content_left{
                display: flex;
              align-items: center;
              margin-top: 8px;
                color: #fff;
                font-size: 3px;
                >.text1{
                    div{
                        margin-bottom: 2px;
                        opacity: 0.8;
                        cursor: pointer;
                    }
                    >div:nth-child(1){
                        color: #fff;
                        font-weight: bold;
                        font-size: 3.5px;
                        margin-bottom: 3px;
                    }
                }
                >.text2{
                    margin-left: 18px;
                    div{
                        margin-bottom: 2px;
                        opacity: 0.8;

                    }
                    >div:nth-child(1){
                        color: #fff;
                        font-weight: bold;
                        font-size: 3.5px;
                        margin-bottom: 3px;
                    }
                }
            }
            .content_right{
                display: flex;
                div{
                    text-align: center;
                    color: #fff;
                    font-size: 3.5px;
                    margin-left: 8px;
                    font-weight: bold;
                }
                img{
                    width: 27px;
                    height: 27px;
                    margin-top: 3px;
                }
            }
        }
        .for_the_record{
            text-align: center;
            color: #fff;
            font-size: 2.5px;
            margin-top: 20px;
            opacity: .5;
            cursor: pointer;
        }
        a{
            color: #fff;
            font-size: 3px;
            font-weight: 400;
        }
        /*div{*/
        /*    font-size: 5px;*/
        /*    color: #eee;*/
        /*    cursor: pointer;*/
        /*}*/
    }
</style>
