<template>
  <div class="plan">
    <NavBar type="plan" />
    <div class="banner">
      <div class="title">医美行业解决方案</div>
      <div class="subtitle">帮助机构快速灵活构建私域内容、商品、营销和服务体系，有机连接微博、自媒体、微官网、公众号、小程序、视频号等私域阵地</div>
      <div class="btn" @click="openAppoint">预约体验</div>
    </div>
    <div class="pain_spot">
      <div class="pain_spot_title">行业痛点</div>
      <div class="pain_spot_content wow slideInUp">
        <div v-for="(item,index) in painSpotList" :key="index">
          <div>
            <img :src="item.img" alt="">
          </div>
          <div class="text">
            <div>{{item.title}}</div>
            <div>{{item.subtitle}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="sub_banner">
      <div class="title">消费医疗行业私域运营白皮书</div>
      <div class="subtitle">您企业的响应速度有多快？这关系到您将如何重塑财务和资产管理，革新客户互动模式，优化供应链和重<br/>新定义劳动力战略。而选择什么样的云应用，将直接影响您的业务敏捷性</div>
      <div class="btn" @click="openAppoint">立即预约</div>
    </div>
    <div class="content_title">医美行业解决方案</div>
    <div class="content">
      <div class="wow slideInUp">
        <div>
          <img src="https://resource.51beauty.com.cn/4d793001-fb6a-4755-be5b-cf5eab708ccc?imageslim" alt="">
        </div>
        <div>
          <div>公私域一体化</div>
          <div>公域广告线索快速通过私域承接，提高服务及时性</div>
        </div>
      </div>
      <div style="background: #FAFBFD">
        <div class="wow slideInUp">
          <div>客户精准画像</div>
          <div>根据用户轨迹等勾勒精准用户画像，帮助咨询更好的与客户沟通</div>
        </div>
        <div class="wow slideInUp">
          <img src="https://resource.51beauty.com.cn/1eaeecf0-c426-4ca5-9ca8-477b85499a11?imageslim" alt="">
        </div>
<!--        <p>-->
<!--          <img src="https://resource.51beauty.com.cn/aca774d8-2132-4dbc-8e6a-f96680bfb0d4?imageslim" alt="">-->
<!--        </p>-->
      </div>
      <div class="wow slideInUp">
        <div>
          <img src="https://resource.51beauty.com.cn/29929956-8c86-4581-a62a-74da9d1066e1?imageslim" alt="">
        </div>
        <div>
          <div>聊天内容存档</div>
          <div>离职交接时历史聊天记录一同交接，客户体验不受损</div>
        </div>
      </div>
      <div style="background: #FAFBFD">
        <div class="wow slideInUp">
          <div>客户资源保全</div>
          <div>为机构沉淀自己的客户资产，灵活进行离职在职继承和分配，员工积极主动跟进和邀约客户</div>
        </div>
        <div class="wow slideInUp">
          <img src="https://resource.51beauty.com.cn/137ec7d2-f3f8-46ac-b17d-0889d4e6b257?imageslim" alt="">
        </div>
        <!--        <p>-->
        <!--          <img src="https://resource.51beauty.com.cn/aca774d8-2132-4dbc-8e6a-f96680bfb0d4?imageslim" alt="">-->
        <!--        </p>-->
      </div>
    </div>
    <!-- 专注消费医疗 -->
    <Bottom />
    <appointment
            :visibleDialog="appointmentVisible"
            @closeAppoint="closeAppoint"
    />
    <Contact/>
  </div>
</template>
<script>
  import NavBar from "@/components/navBar.vue";
  import Bottom from "@/components/bottom.vue";
  import Appointment from "@/components/appointment.vue";
  import Contact from "@/components/contact.vue";
  import { WOW } from 'wowjs'

  export default {
    components: {
      NavBar,
      Bottom,
      Appointment,
      Contact
    },
    data() {
      return {
        painSpotList:[
          {
            img:'https://resource.51beauty.com.cn/3173fcd8-c51c-495d-b29d-5000770da8bd?imageslim',
            title: '获客成本高',
            subtitle: '电商已成为医美机构获客的主流平台，但因竞争激烈，造成获客成本高，机构被迫陷入价格战，亟需寻找新的流量池'
          },
          {
            img:'https://resource.51beauty.com.cn/79186bf7-bc72-4113-8ed5-b46361f029d5?imageslim',
            title: '客户画像不清晰',
            subtitle: '咨询师无法在个人微信进行标签化统一管理，营销活动无法精确匹配到有需求的客户'
          },
          {
            img:'https://resource.51beauty.com.cn/71b590a5-dc9c-4cc7-b0c4-f34cb48aa078?imageslim',
            title: '业绩提升难',
            subtitle: '机构难以了解咨询师与客户之间的沟通内容，服务质量无法得到保障，业绩提升难'
          },
          {
            img:'https://resource.51beauty.com.cn/b16c25c9-92b9-42d4-bf71-38f5669b34f7?imageslim',
            title: '客户留存差',
            subtitle: '咨询师离职时，可能私自带走客户或在职咨询师可能产生飞单、私单风险，造成一定的客户资源流失'
          },
        ],
        appointmentVisible:false
      };
    },
    mounted() {
      new WOW().init()
    },
    methods: {
      // 关闭预约弹层
      closeAppoint (e) {
        this.appointmentVisible = e
      },
      // 打开预约弹层
      openAppoint () {
        this.appointmentVisible = true
      },
    },
  };
</script>
<style scoped lang="less">
  .plan{
    .banner{
      background: url("https://resource.51beauty.com.cn/a91d998b-2e1e-4dd8-80d6-7e574a4854cf?imageslim") no-repeat;
      background-size: cover;
      width: 375px;
      height: 94px;
      .title{
        padding: 27px 0 0 70px;
        font-weight: bold;
        font-size: 10px;
      }
      .subtitle{
        width: 142px;
        margin-left: 70px;
        margin-top: 4px;
        color: #273755;
        font-size: 4px;
        line-height: 7px;
      }
      .btn{
        background: #2C68FF;
        font-size: 4px;
        margin-top: 11px;
        height: 10px;
        width: 33px;
        margin-left: 70px;
        border-radius: 1px;
        line-height: 10px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
    }
    .pain_spot{
      width: 235px;
      margin: 0 auto;
      margin-top: 27px;
      .pain_spot_title{
        font-size: 8px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 12px;
      }
      >.pain_spot_content{
        padding-bottom: 24px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        >div{
          width: 115px;
          height: 39px;
          display: flex;
          align-items: center;
          box-shadow: 0px 2px 4px 0px rgba(55,99,170,0.1);
          border-radius: 2px;
          padding: 10px 0;
          margin-bottom: 5px;
          img{
            width: 18px;
            height: 18px;
            display: block;
            margin-left: 10px;
          }
          .text{
            margin-left: 7px;
            width: 75px;
            >div:nth-child(1){
              margin-bottom: 4px;
              font-size: 4px;
              font-weight: bold;
            }
            >div:nth-child(2){
              font-size: 2.8px;
              color: #273755;
              line-height: 5px;
            }
          }
        }
      }
    }
    .sub_banner{
      background: url("https://resource.51beauty.com.cn/78ec4dfa-9b8a-430f-a937-1f715523727f?imageslim") no-repeat;
      background-size: cover;
      width: 375px;
      height: 82px;
      .title{
        font-size: 6px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        padding-top: 16px;
      }
      .subtitle{
        font-size: 3px;
        color: #fff;
        margin-top: 8px;
        text-align: center;
        opacity: .8;
      }
      .btn{
        width: 33px;
        height: 10px;
        background: #2C68FF;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
        border-radius: 1px;
        color: #fff;
        font-size: 4px;
        text-align: center;
        line-height: 10px;
        margin: 0 auto;
        margin-top: 14px;
        cursor: pointer;

      }
    }
    .content_title{
      font-size: 8px;
      font-weight: bold;
      text-align: center;
      margin: 20px 0 9px 0
    }
    .content{
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 28px;
        position: relative;
        padding-top: 20px;
        img{
          width: 117px;
          display: block;
        }
        >div:nth-child(2){
          margin-left: 10px;
        }
        >div>div:nth-child(1){
          font-size: 5px;
          font-weight: bold;
        }
        >div>div:nth-child(2){
          font-size: 4px;
          color: #273755;
          margin-top: 4px;
          width: 113px;
        }
        >p{
          position: absolute;
          right: 5px;
          bottom: 0;
          margin: 0;
          img{
            width: 131px;
            height: 54px;
            display: block;
          }
        }
      }
    }
  }
</style>