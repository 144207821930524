var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advertising"},[_c('NavBar1',{attrs:{"type":"product"}}),_c('div',{staticClass:"banner"},[_c('div',{staticClass:"title"},[_vm._v("单病种数字化服务")]),_vm._m(0),_c('div',{staticClass:"btn",on:{"click":_vm.openAppoint}},[_vm._v("预约体验")])]),_vm._m(1),_c('div',{staticClass:"exhibition"},[_c('div',{staticClass:"exhibition_con"},[_c('div',[_vm._v("新闻与活动")]),_c('div',{staticClass:"wow slideInUp"},[_c('div',{staticClass:"exhibition_item",on:{"click":function($event){return _vm.article('https://cloud.tencent.com/developer/article/1892234?from=article.detail.1902177')}}},[_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"exhibition_item",on:{"click":function($event){return _vm.article('https://cloud.tencent.com/document/product/306/47166')}}},[_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"exhibition_item",on:{"click":function($event){return _vm.article('https://baijiahao.baidu.com/s?id=1718001203675989497&wfr=spider&for=pc')}}},[_vm._m(6),_vm._m(7)])])])]),_c('Bottom'),_c('appointment',{attrs:{"visibleDialog":_vm.appointmentVisible},on:{"closeAppoint":_vm.closeAppoint}}),_c('Contact')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subtitle"},[_vm._v("定期跟踪了解患者病情变化，通过发布随访任务，患者配合完成，"),_c('br'),_vm._v("对患者进行专业性康复指导")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"wow slideInUp"},[_c('div',[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/900c0e5e-da15-4829-9f2b-897e7c57c657?imageslim","alt":""}})]),_c('div',[_c('div',[_vm._v("提升患者诊疗体验")]),_c('div',[_vm._v("自定义精细化分组、自定义标准化执行动作（任务）、自定义任务相关话术和物料。提升诊疗效果和医院服务口碑")])])]),_c('div',{staticStyle:{"background":"#FAFBFD"}},[_c('div',{staticClass:"wow slideInUp"},[_c('div',[_vm._v("减轻医务人员工作量")]),_c('div',[_vm._v("准确、专业、自动化执行任务，员工操作便捷，每个员工可覆盖更多服务客户，有效降低随访成本")])]),_c('div',{staticClass:"wow slideInUp"},[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/4ae783f9-8620-4832-b9e8-a6ee95ebc2c6?imageslim","alt":""}})]),_c('p',[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/aca774d8-2132-4dbc-8e6a-f96680bfb0d4?imageslim","alt":""}})])]),_c('div',{staticClass:"wow slideInUp"},[_c('div',[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/fda2e541-e633-48e6-b6d4-cc979f796aeb?imageslim","alt":""}})]),_c('div',[_c('div',[_vm._v("执行过程可量化可追踪")]),_c('div',[_vm._v("支持查看每组任务下子任务执行情况，实现运营队伍全环节执行和绩效管理透明")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_img"},[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/f17ab896-e595-4a3e-bda8-7258a73e1e48?imageslim","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_text"},[_c('div',{staticClass:"title"},[_vm._v("腾讯SaaS加速器三期总决选")]),_c('div',{staticClass:"subtitle"},[_vm._v("云晰科技从1100家报名SaaS企业中顺利突围，获得最终角逐Top 40席位的入场券。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_img"},[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/a5826992-7c89-4adf-bbca-c986ca84e718?imageslim","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_text"},[_c('div',{staticClass:"title"},[_vm._v("云晰科技已通过腾讯云千帆计划复选")]),_c('div',{staticClass:"subtitle"},[_vm._v("营销云产品（医美版、口腔版、眼科版）已上架腾讯云市场，成为“千帆甄选”推荐产品。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_img"},[_c('img',{attrs:{"src":"https://resource.51beauty.com.cn/4bc47af4-78d0-4a64-95b3-be41e94b212e?imageslim","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exhibition_text"},[_c('div',{staticClass:"title"},[_vm._v("云晰科技和企业微信官方合作组织沙龙会")]),_c('div',{staticClass:"subtitle"},[_vm._v("云晰与企业微信医疗板块深度合作共创，将企微作为腾讯新流量生态的结合点，帮助消费医疗…")])])
}]

export { render, staticRenderFns }