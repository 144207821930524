<template>
  <div class="plan">
    <NavBar type="plan" />
    <div class="banner">
      <div class="title">肿瘤行业解决方案</div>
      <div class="subtitle"></div>
      <div class="btn" @click="openAppoint">预约体验</div>
    </div>
    <div class="the-wait">
      <img src="https://resource.51beauty.com.cn/2d1bfd12-e6c1-40f7-a002-d2afe0d9f4b4?imageslim" alt="">
      <div class="desc">重大利好 发布上线 敬请期待！</div>
    </div>

    <!-- 专注消费医疗 -->
    <Bottom />
    <appointment
            :visibleDialog="appointmentVisible"
            @closeAppoint="closeAppoint"
    />
    <Contact/>
  </div>
</template>
<script>
  import NavBar from "@/components/navBar.vue";
  import Bottom from "@/components/bottom.vue";
  import Appointment from "@/components/appointment.vue";
  import Contact from "@/components/contact.vue";
  import { WOW } from 'wowjs'

  export default {
    components: {
      NavBar,
      Bottom,
      Appointment,
      Contact
    },
    data() {
      return {
        painSpotList:[
          {
            img:'https://resource.51beauty.com.cn/0f5a19cd-b703-4051-9c4b-aead16509b53?imageslim',
            title: '线索转化成本高',
            subtitle: '流量资源缺乏精度，且缺少数据反馈，投放成本居高不下'
          },
          {
            img:'https://resource.51beauty.com.cn/98beecdb-dbd8-430e-afdc-7175504269ee?imageslim',
            title: '服务流程不统一',
            subtitle: '缺乏服务标准化流程，导致回访、再邀约、二次开单效率低'
          },
          {
            img:'https://resource.51beauty.com.cn/8a211e79-e1d8-4c1e-9073-63c66b00c433?imageslim',
            title: '老客带新占比低',
            subtitle: '缺乏裂变工具，老客带新率太低'
          },
          {
            img:'https://resource.51beauty.com.cn/61c810ae-e0aa-43b0-81c9-1e352a9450e8?imageslim',
            title: '连锁模式管理难',
            subtitle: '集团连锁模式管理难，数据权限模糊，难以实现统一有效管理'
          },
        ],
        appointmentVisible:false
      };
    },
    mounted() {
      new WOW().init()
    },
    methods: {
      // 关闭预约弹层
      closeAppoint (e) {
        this.appointmentVisible = e
      },
      // 打开预约弹层
      openAppoint () {
        this.appointmentVisible = true
      },
    },
  };
</script>
<style scoped lang="less">
  .plan{
    .the-wait{
      width: 100%;
      text-align: center;
      padding: 20px 0;
      .desc{
        color: #777;
      }
      img{
        width: 80px;
        height: 80px;
        display: block;
        margin: 0 auto;
      }
    }
    .banner{
      background: url("https://resource.51beauty.com.cn/bb4a4e5d-682e-429a-b443-d6b6225f6c5b?imageslim") no-repeat;
      background-size: cover;
      width: 375px;
      height: 94px;
      .title{
        padding: 27px 0 0 70px;
        font-weight: bold;
        font-size: 10px;
      }
      .subtitle{
        width: 142px;
        margin-left: 70px;
        margin-top: 4px;
        color: #273755;
        font-size: 4px;
        line-height: 7px;
        height: 6px;
      }
      .btn{
        background: #2C68FF;
        font-size: 4px;
        margin-top: 11px;
        height: 10px;
        width: 33px;
        margin-left: 70px;
        border-radius: 1px;
        line-height: 10px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
    }
    .pain_spot{
      width: 235px;
      margin: 0 auto;
      margin-top: 27px;
      .pain_spot_title{
        font-size: 8px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 12px;
      }
      >.pain_spot_content{
        padding-bottom: 24px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        >div{
          width: 115px;
          height: 39px;
          display: flex;
          align-items: center;
          box-shadow: 0px 2px 4px 0px rgba(55,99,170,0.1);
          border-radius: 2px;
          padding: 10px 0;
          margin-bottom: 5px;
          img{
            width: 18px;
            height: 18px;
            display: block;
            margin-left: 10px;
          }
          .text{
            margin-left: 7px;
            width: 75px;
            >div:nth-child(1){
              margin-bottom: 4px;
              font-size: 4px;
              font-weight: bold;
            }
            >div:nth-child(2){
              font-size: 2.8px;
              color: #273755;
              line-height: 5px;
            }
          }
        }
      }
    }
    .sub_banner{
      background: url("https://resource.51beauty.com.cn/78ec4dfa-9b8a-430f-a937-1f715523727f?imageslim") no-repeat;
      background-size: cover;
      width: 375px;
      height: 82px;
      .title{
        font-size: 6px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        padding-top: 16px;
      }
      .subtitle{
        font-size: 3px;
        color: #fff;
        margin-top: 8px;
        text-align: center;
        opacity: .8;
      }
      .btn{
        width: 33px;
        height: 10px;
        background: #2C68FF;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
        border-radius: 1px;
        color: #fff;
        font-size: 4px;
        text-align: center;
        line-height: 10px;
        margin: 0 auto;
        margin-top: 14px;
        cursor: pointer;
      }
    }
    .content_title{
      font-size: 8px;
      font-weight: bold;
      text-align: center;
      margin: 20px 0 9px 0
    }
    .content{
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 28px;
        position: relative;
        padding-top: 20px;
        img{
          width: 117px;
          display: block;
        }
        >div:nth-child(2){
          margin-left: 10px;
        }
        >div>div:nth-child(1){
          font-size: 5px;
          font-weight: bold;
        }
        >div>div:nth-child(2){
          font-size: 4px;
          color: #273755;
          margin-top: 4px;
          width: 113px;
        }
        >p{
          position: absolute;
          right: 5px;
          bottom: 0;
          margin: 0;
          img{
            width: 131px;
            height: 54px;
            display: block;
          }
        }
      }
    }
  }
</style>