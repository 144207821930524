<template>
  <div class="plan">
    <NavBar type="plan" />
    <div class="banner">
      <div class="title">口腔行业解决方案</div>
      <div class="subtitle">降低口腔门诊的线上获客成本、提高线下获客效率，解决客户管理不集中、项目跟单和老客开发困难等问题</div>
      <div class="btn" @click="openAppoint">预约体验</div>
    </div>
    <div class="pain_spot">
      <div class="pain_spot_title">行业痛点</div>
      <div class="pain_spot_content wow slideInUp">
        <div v-for="(item,index) in painSpotList" :key="index">
          <div>
            <img :src="item.img" alt="">
          </div>
          <div class="text">
            <div>{{item.title}}</div>
            <div>{{item.subtitle}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="sub_banner">
      <div class="title">消费医疗行业私域运营白皮书</div>
      <div class="subtitle">您企业的响应速度有多快？这关系到您将如何重塑财务和资产管理，革新客户互动模式，优化供应链和重<br/>新定义劳动力战略。而选择什么样的云应用，将直接影响您的业务敏捷性</div>
      <div class="btn" @click="openAppoint">立即预约</div>
    </div>
    <div class="content_title">口腔行业解决方案</div>
    <div class="content">
      <div class="wow slideInUp">
        <div>
          <img src="https://resource.51beauty.com.cn/44371f81-13e1-4ac7-88a8-70dc23b842bc?imageslim" alt="">
        </div>
        <div>
          <div>任务提醒</div>
          <div>任务中心提示咨询师每日工作计划，高效管理日常工作</div>
        </div>
      </div>
      <div class="wow slideInUp" style="background: #FAFBFD">
        <div>
          <div>话术赋能</div>
          <div>模版式快速导入相关话术，云晰提供初始化行业话术，高效、专业服务客户</div>
        </div>
        <div>
          <img src="https://resource.51beauty.com.cn/ba53c260-a713-46bf-b062-c4b3738c7db7?imageslim" alt="">
        </div>
<!--        <p>-->
<!--          <img src="https://resource.51beauty.com.cn/aca774d8-2132-4dbc-8e6a-f96680bfb0d4?imageslim" alt="">-->
<!--        </p>-->
      </div>
      <div class="wow slideInUp">
        <div>
          <img src="https://resource.51beauty.com.cn/a647612a-b5af-4f9e-ab88-da9bf9e22ff5?imageslim" alt="">
        </div>
        <div>
          <div>精准营销</div>
          <div>按照客户活动轨迹，推送个性化的活动内容，判断客户真实意向，做精准管理，长期维护</div>
        </div>
      </div>
      <div style="background: #FAFBFD">
        <div class="wow slideInUp">
          <div>建档跟进</div>
          <div>专门为口腔医疗客户提供健康档案、诊疗档案、诊疗日记，为患者记录初诊/面诊，治疗，恢复（变美）过程，提供个人问题的专业指导和参考</div>
        </div>
        <div class="wow slideInUp">
          <img src="https://resource.51beauty.com.cn/7fed41a9-5da5-4dc6-a255-dd6087b654c9?imageslim" alt="">
        </div>
        <!--        <p>-->
        <!--          <img src="https://resource.51beauty.com.cn/aca774d8-2132-4dbc-8e6a-f96680bfb0d4?imageslim" alt="">-->
        <!--        </p>-->
      </div>
    </div>
    <!-- 专注消费医疗 -->
    <Bottom />
    <appointment
            :visibleDialog="appointmentVisible"
            @closeAppoint="closeAppoint"
    />
    <Contact/>
  </div>
</template>
<script>
  import NavBar from "@/components/navBar.vue";
  import Bottom from "@/components/bottom.vue";
  import Appointment from "@/components/appointment.vue";
  import Contact from "@/components/contact.vue";
  import { WOW } from 'wowjs'

  export default {
    components: {
      NavBar,
      Bottom,
      Appointment,
      Contact
    },
    data() {
      return {
        painSpotList:[
          {
            img:'https://resource.51beauty.com.cn/40e4fee9-52cc-48b9-9b52-2784c4cfb724?imageslim',
            title: '客户跟进慢',
            subtitle: '咨询师日常微信沟通跟进的时效性无法管控，很可能出现忘记跟进、过长时间未跟进等情况'
          },
          {
            img:'https://resource.51beauty.com.cn/e6afc343-f6de-48c8-a807-2615e145c885?imageslim',
            title: '话术质量差',
            subtitle: '由于咨询师的沟通话术不统一，导致咨询师的线上转化率参差不齐'
          },
          {
            img:'https://resource.51beauty.com.cn/52773a35-9122-47cb-a29c-6c1a2b1b197d?imageslim',
            title: '客户盘活难',
            subtitle: '无法了解客户真实意向，盲目发送营销内容，沉睡客户无法激活'
          },
          {
            img:'https://resource.51beauty.com.cn/4b902cbb-5cc7-452d-afac-6bbbdde62717?imageslim',
            title: '转化效率低',
            subtitle: '咨询师缺乏有效促进客户运营转化的工具，常常出现脱离客户实际情况的尬聊'
          },
        ],
        appointmentVisible:false
      };
    },
    mounted() {
      new WOW().init()
    },
    methods: {
      // 关闭预约弹层
      closeAppoint (e) {
        this.appointmentVisible = e
      },
      // 打开预约弹层
      openAppoint () {
        this.appointmentVisible = true
      },
    },
  };
</script>
<style scoped lang="less">
  .plan{
    .content_title{
      font-size: 8px;
      font-weight: bold;
      text-align: center;
      margin: 20px 0 9px 0
    }
    .banner{
      background: url("https://resource.51beauty.com.cn/47e135e7-2f9c-4587-b474-59250d618182?imageslim") no-repeat;
      background-size: cover;
      width: 375px;
      height: 94px;
      .title{
        padding: 27px 0 0 70px;
        font-weight: bold;
        font-size: 10px;
      }

      .subtitle{
        width: 142px;
        margin-left: 70px;
        margin-top: 4px;
        color: #273755;
        font-size: 4px;
        line-height: 7px;
      }
      .btn{
        background: #2C68FF;
        font-size: 4px;
        margin-top: 11px;
        height: 10px;
        width: 33px;
        margin-left: 70px;
        border-radius: 1px;
        line-height: 10px;
        text-align: center;
        color: #fff;
        cursor: pointer;

      }
    }
    .pain_spot{
      width: 235px;
      margin: 0 auto;
      margin-top: 27px;
      .pain_spot_title{
        font-size: 8px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 12px;
      }
      >.pain_spot_content{
        padding-bottom: 24px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        >div{
          width: 115px;
          height: 39px;
          display: flex;
          align-items: center;
          box-shadow: 0px 2px 4px 0px rgba(55,99,170,0.1);
          border-radius: 2px;
          padding: 10px 0;
          margin-bottom: 5px;
          img{
            width: 18px;
            height: 18px;
            display: block;
            margin-left: 10px;
          }
          .text{
            margin-left: 7px;
            width: 75px;
            >div:nth-child(1){
              margin-bottom: 4px;
              font-size: 4px;
              font-weight: bold;
            }
            >div:nth-child(2){
              font-size: 2.8px;
              color: #273755;
              line-height: 5px;
            }
          }
        }
      }
    }
    .sub_banner{
      background: url("https://resource.51beauty.com.cn/78ec4dfa-9b8a-430f-a937-1f715523727f?imageslim") no-repeat;
      background-size: cover;
      width: 375px;
      height: 82px;
      .title{
        font-size: 6px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        padding-top: 16px;
      }
      .subtitle{
        font-size: 3px;
        color: #fff;
        margin-top: 8px;
        text-align: center;
        opacity: .8;
      }
      .btn{
        width: 33px;
        height: 10px;
        background: #2C68FF;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
        border-radius: 1px;
        color: #fff;
        font-size: 4px;
        text-align: center;
        line-height: 10px;
        margin: 0 auto;
        margin-top: 14px;
        cursor: pointer;
      }
    }
    .content{
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 28px;
        position: relative;
        padding-top: 20px;
        img{
          width: 117px;
          display: block;
        }
        >div:nth-child(2){
          margin-left: 10px;
        }
        >div>div:nth-child(1){
          font-size: 5px;
          font-weight: bold;
        }
        >div>div:nth-child(2){
          font-size: 4px;
          color: #273755;
          margin-top: 4px;
          width: 113px;
        }
        >p{
          position: absolute;
          right: 5px;
          bottom: 0;
          margin: 0;
          img{
            width: 131px;
            height: 54px;
            display: block;
          }
        }
      }
    }
  }
</style>