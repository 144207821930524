<template>
  <div class="home2">
    <NavBar type="home" />
    <!-- 专注消费医疗 -->
    <div class="content">
      <div class="banner">
        <div class="banner_line">
          <div class="banner_info">XITECH SaaS</div>
          <div class="banner_oneself">专注消费医疗</div>
          <div class="banner_img"></div>
          <div class="banner_title">业绩增长整体数字化服务商</div>
        </div>
      </div>
    </div>
    <!-- 打造数据运营 -->
    <div class="medical_title">
      <div class="medical_last">打造数据运营闭环 助力业绩高效增长</div>
      <div class="medical_sel"></div>
      <div class="medical_info">一站式数字化营销解决方案</div>
    </div>
    <!-- 获客云 -->
    <div class="Marketing">
      <div class="plan_info flex">
        <div class="plan_item" v-for="(item, index) in conversion" :key="index">
          <div class="bg_sel"></div>
          <div class="inner_start">
            <div class="inner flex_column">
              <div class="icon">
                <img class="def" :src="item.icon" />
                <img class="sel" :src="item.selIcon" />
              </div>
              <div class="title">{{ item.title }}</div>
              <div class="sub_title">
                <div>{{ item.subtitle1 }}</div>
              </div>
              <div class="btn_1" @click="goPage">查看详情 >>></div>
              <div class="btn_2 flex" @click="openAppoint">预约体验</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 深耕服务消费医疗 -->
    <div class="consumption_itemsel">
      <div class="consumption_img">
        <div class="consumption_title">深耕服务消费医疗 经营规模强大</div>
      </div>
    </div>
    <!-- 获客转化 -->
    <div class="forward_info">
      <div class="forward_are">获客+转化+管理</div>
      <div class="forward-plan">帮助消费医疗企业有效经营</div>
      <div class="forward_last"></div>
      <div class="forward_arrive">4大核心优势满足企业私域营销需求</div>
    </div>
    <div class="figure_item" v-for="(item, index) in plans2" :key="index">
      <img class="figure_picture" :src="item.img" alt="" />
      <div class="figure-plan">{{ item.selIcon }}</div>
      <img class="figure_opposite" :src="item.imgicon" alt="" />
      <div class="figure_info">{{ item.title }}</div>
       <div class="figure_navtitle">{{item.navtitle}}</div>
    </div>
    <!-- 基于解决方案的专业实施服务 -->
    <div class="service_put">
      <div class="service_item">
        <div class="service_based">
          基于解决方案的专业实施服务 满足客户差异化需求
        </div>
        <div class="service_last"></div>
        <div class="service_implement">7大实施流程为营销落地保驾护航</div>
        <div class="service_info">
          <img
            class="service_img"
            src="https://resource.51beauty.com.cn/8383d3e3-92f0-4131-851a-7d2927bde3c5?imageslim"
            alt=""
          />
        </div>
        <div></div>
      </div>
    </div>
    <!-- 切换医美口腔眼科植发 -->
    <div class="cur_last">
      <div class="cur_title">1000+消费医疗企业的共同选择</div>
      <div class="tab_cur">
        <div @click="cur = 0" :class="{ active: cur == 0 }">医美</div>
        <div @click="cur = 1" :class="{ active: cur == 1 }">口腔</div>
        <div @click="cur = 2" :class="{ active: cur == 2 }">眼科</div>
        <div @click="cur = 3" :class="{ active: cur == 3 }">植发</div>
      </div>
      <div>
        <div v-show="cur == 0">
          <div class="tab-first">
            <img
              src="https://resource.51beauty.com.cn/85e87d09-a031-4a3e-8114-f5f137f8682a?imageslim"
              alt=""
            />
          </div>
        </div>
        <div v-show="cur == 1">
          <div class="tab-first">
            <img
              src="https://resource.51beauty.com.cn/85e87d09-a031-4a3e-8114-f5f137f8682a?imageslim"
              alt=""
            />
          </div>
        </div>
        <div v-show="cur == 2">
          <div class="tab-first">
            <img
              src="https://resource.51beauty.com.cn/85e87d09-a031-4a3e-8114-f5f137f8682a?imageslim"
              alt=""
            />
          </div>
        </div>
        <div v-show="cur == 3">
          <div class="tab-first">
            <img
              src="https://resource.51beauty.com.cn/85e87d09-a031-4a3e-8114-f5f137f8682a?imageslim"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 联系方式 -->
    <div class="first_contact">
      <div class="contact_img">
        <div class="first_information">联系方式</div>
        <div class="first_titles">市场邮箱：market@xitech.com.cn</div>
        <div class="first_datas">
          公司地址：北京市朝阳区百子湾东朝时代西区109室
        </div>
        <div class="first_appley">
          <div class="first-one">
            <div class="first">
              <span class="first-Medical">医美行业</span>
            </div>
          </div>
          <div class="first-two">
            <div class="first">
              <span class="first-cavity">口腔行业</span>
            </div>
          </div>
        </div>
        <div class="first_many">
          <div>更多详情</div>
          <div>请添加云晰营销官企业微信</div>
          <div>随时为您答疑解惑</div>
        </div>
      </div>
    </div>
    <!-- 立即预约 -->
    <div class="make">
      <div class="make-img">
        <div class="make-make">立即预约</div>
        <div class="make-host">即体验火爆的营销工具</div>
        <div class="make-experience" @click="openAppoint">预约体验</div>
      </div>
    </div>
    <div class="call" @click="openAppoint">
      <img src="../assets/fuceng.png" alt="" />
    </div>
    <Bottom />
    <appointment
      :visibleDialog="appointmentVisible"
      @closeAppoint="closeAppoint"
    />
  </div>
</template>
<script>
import NavBar from "@/components/navBar.vue";
import Bottom from "@/components/bottom.vue";
import Appointment from "@/components/appointment.vue";
export default {
  components: {
    NavBar,
    Bottom,
    Appointment,
  },
  data() {
    return {
      cur: 0, //默认选中第一个tab
      appointmentVisible: false, // 预约弹层是否显示
      plans2: [
        {
          img: "	https://resource.51beauty.com.cn/8c8793db-f374-45cf-9a38-8b3fb1d2fc5b?imageslim", // 主图片
          selIcon: "精耕数字化消费医疗行业", // // 主标题
          title: "数字化服务标准", // 标题
          navtitle:'重塑消费医疗产业',
          imgicon: require("../assets/title_icon.png"), //图
        },
        {
          img: "	https://resource.51beauty.com.cn/4d454389-ba0c-4e42-a733-e294c653bc4f?imageslim", // 主图片
          selIcon: "一手核心媒介资源全面覆盖", // // 主标题
          title: "核心新媒体资源+全渠道资源 ", // 标题
          navtitle:'精准快速整合垂直流量',
         imgicon: require("../assets/title_icon.png"), //图
        },
        {
          img: "	https://resource.51beauty.com.cn/e47fe0a4-a78c-4a45-bc2d-e5059012cc4b?imageslim", // 主图片
          selIcon: "营销工具+陪伴式营销运营", // // 主标题
         title: "定制化营销产品结合配套运营服务", // 标题
          navtitle:'医疗消费企业的最优选择',
         imgicon: require("../assets/title_icon.png"), // 图
        },
        {
          img: "	https://resource.51beauty.com.cn/15ec0a46-3a73-4361-9468-e228cabc04b4?imageslim", // 主图片
          selIcon: "全员赋能形成可执行落地闭环", // // 主标题
          title: "基于业务场景设定角色  ", // 标题
          navtitle:'确保营销动作落地, 对实际效果负责',
         imgicon: require("../assets/title_icon.png"), //图
        },
      ],
      conversion: [
        {
          icon: "https://resource.51beauty.com.cn/fddfef38-39b5-4395-9b2a-4b3ff78cb0d3?imageslim", // 默认icon
          selIcon:
            "https://resource.51beauty.com.cn/35d89a8b-841a-483c-b637-86d7d8d76a10?imageslim", // 选中icon
          title: "获客云", // 主标题
          subtitle1: "基于公私域打通的一站式获客增长方案", // 副标题
        },
        {
          icon: "https://resource.51beauty.com.cn/b5320050-0f7d-4b43-87c3-0cae078b38da?imageslim", // 默认icon
          selIcon:
            "https://resource.51beauty.com.cn/4f5fc03e-6faf-4938-8d44-8665a50c87b4?imageslim", // 选中icon
          title: "转化云", // 主标题
          subtitle1: "基于企业微信SCRM的成交转化方案", // 副标题
        },
        {
          icon: "https://resource.51beauty.com.cn/eedb36ba-bc2c-41e9-8f40-b0e03b48220f?imageslim", // 默认icon
          selIcon:
            "https://resource.51beauty.com.cn/149d215a-895f-4f8b-9040-94e94f872eb3?imageslim", // 选中icon
          title: "管理云", // 主标题
          subtitle1: "基于数据智能分析的科学管理方案", // 副标题
        },
      ],
    };
  },
  methods: {
    // 关闭预约弹层
    closeAppoint(e) {
      this.appointmentVisible = e;
    },
    // 打开预约弹层
    openAppoint() {
      this.appointmentVisible = true;
    },
    // 查看详情-跳转至产品页面
    goPage() {
      this.$router.push({
        path: "/my",
      });
    },
  },
};
</script>
<style scoped lang="less">
.home2 {
  position: relative;
  padding-top: 18px;
  .call {
    position: fixed;
    bottom: 50px;
    left: 335px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .content {
    width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .banner {
      width: 375px;
      height: 526px;
      background: url("https://resource.51beauty.com.cn/9d0c339f-7000-4596-b5b7-4c85fd83e4f2?imageslim")
      no-repeat;
      background-size: 100% 100%;
      .banner_line {
        display: flex;
        align-items: center;
        flex-direction: column;
        .banner_info {
          font-size: 37px;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.9;
          margin-top: 62px;
        }
        .banner_oneself {
          font-size: 35px;
          font-weight: bold;
          color: #2278f0;
          margin-top: -26px;
        }
        .banner_img {
          width: 38px;
          height: 4px;
          background: url("../assets/title_icon.png") no-repeat;
          background-size: 100% 100%;
          margin-top: 31px;
          margin-bottom: 19px;
        }
        .banner_title {
          font-size: 17px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
  .medical_title {
    width: 375px;
    height: 203px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .medical_last {
      width: 165px;
      height: 48px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      line-height: 29px;
      margin-top: 53px;
    }
    .medical_sel {
      width: 38px;
      height: 4px;
      background: url("../assets/title_icon.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 20px;
      margin-bottom: 27px;
    }
    .medical_info {
      font-size: 13px;
      font-weight: 400;
      color: #414141;
      line-height: 18px;
    }
  }
  .forward_info {
    width: 375px;
    height: 218px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 49px;
    .forward_are {
      color: #196ff1;
      font-size: 20px;
      margin-top: 60px;
    }
    .forward-plan {
      color: #333333;
      font-size: 20px;
    }
    .forward_last {
      width: 38px;
      height: 4px;
      background: url("../assets/title_icon.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 20px;
    }
    .forward_arrive {
      font-size: 13px;
      font-weight: 400;
      color: #414141;
      line-height: 18px;
      margin-top: 25px;
    }
  }
  // 精耕数字化消费医疗行业
  .figure_item {
    width: 375px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
    .figure_picture {
      width: 204px;
      height: 150px;
    }
    .figure_opposite {
      width: 24px;
      height: 3px;
      margin-top: 15px;
    }
    .figure-plan {
      font-size: 15px;
      font-weight: 400;
      color: #333333;
      margin-top: 13px;
    }
    .figure_info {
      font-size: 12px;
      font-weight: 400;
      color: #414141;
      margin-top: 12px;
    }
    .figure_navtitle{
      font-size: 12px;
      font-weight: 400;
      color: #414141;
    }
  }
  .consumption_itemsel {
    height: 622px;
    width: 375px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .consumption_img {
      background: url("https://resource.51beauty.com.cn/ffe06a6e-5a47-4e00-a97c-235d18129021?imageslim")
      no-repeat;
      background-size: 100% 100%;
      width: 375px;
      height: 662px;
    }
    .consumption_title {
      width: 164px;
      height: 48px;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      margin: auto;
      margin-top: 59px;
    }
  }
  .first_infolet {
    width: 375px;
    background: cornflowerblue;
    display: flex;
    background: red;

    flex-direction: column;
    .first_infos {
      background: url("https://resource.51beauty.com.cn/41ab0d68-3361-4357-a694-6720c32fa2a0?imageslim")
      no-repeat;
      background-size: 100% 100%;
      height: 320px;
      width: 375px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .first-title {
      margin-top: 13px;
      font-size: 18px;
      font-weight: 500;
      color: #196ff1;
    }
    .first-data {
      font-size: 12px;
      margin-top: 13px;
      font-weight: 400;
      color: #414141;
      margin-top: 25px;
    }
    .first_details {
      font-size: 12px;
      font-weight: 400;
      color: #196ff1;
      margin-top: 25px;
    }
    .first_make {
      width: 105px;
      height: 28px;
      background: #196ff1;
      color: #ffffff;
      text-align: center;
      line-height: 28px;
      font-size: 13px;
      border-radius: 5px;
      margin-top: 12px;
      transition: all 0.35s;
      cursor: pointer;
    }
    img {
      margin-top: 46px;
      width: 44px;
      height: 44px;
    }
  }
  .service_put {
    width: 375px;
    display: flex;
    background: url("https://resource.51beauty.com.cn/51bef336-4fb2-4246-9d08-2b2bde4ceaf6?imageslim")
    no-repeat;
    background-size: 100% 100%;
    align-items: center;
    flex-direction: column;
    .service_item {
      display: flex;
      align-items: center;
      flex-direction: column;
      .service_based {
        width: 269px;
        height: 48px;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 29px;
        text-align: center;
        margin-top: 60px;
      }
      .service_last {
        width: 38px;
        height: 4px;
        background: url("../assets/title_icon.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 20px;
      }
      .service_implement {
        margin-top: 25px;
        height: 13px;
        font-size: 13px;
        font-weight: 400;
        color: #414141;
      }
      .service_info {
        display: flex;
        flex-direction: row;
        margin-top: 28px;
        .service_img {
          margin-top: 28px;
          width: 366px;
          height: 503px;
          // background-size: 100% 100%;
        }
      }
    }
  }
  .first_contact {
    width: 375px;
    height: 425px;
    display: flex;
    .contact_img {
      background: url("https://resource.51beauty.com.cn/75f95cf7-81f1-4132-9dfc-1f37170432f6?imageslim")
      no-repeat;
      background-size: 100% 100%;
      display: flex;
      width: 375px;
      height: 425px;
      flex-direction: column;
      .first_information {
        font-size: 17px;
        font-weight: 400;
        color: #ffffff;
        margin-top: 66px;
        margin-left: 25px;
      }
      .first_titles {
        font-size: 13px;
        margin-top: 20px;
        margin-left: 25px;
        font-weight: 400;
        color: #ffffff;
      }
      .first_datas {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        margin-top: 5px;
        margin-left: 25px;
      }
      .first_many {
        height: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        margin-left: 25px;
        margin-top: 20px;
        margin-bottom: 60px;
      }
      .first_appley {
        width: 375px;
        height: 165px;
        display: flex;
        .first-one {
          width: 107px;
          height: 141px;
          display: flex;
          flex-direction: column;
          margin-left: 25px;
          margin-top: 25px;
          .first {
            width: 107px;
            height: 141px;
            display: block;
            background: url("https://resource.51beauty.com.cn/a9c5f290-5392-4916-bcf6-fde36c947e77?imageslim")
            no-repeat;
            background-size: 100% 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .first-Medical {
              font-size: 15px;
              color: #ffffff;
              margin-top: 115px;
            }
          }
        }
        .first-two {
          width: 107px;
          height: 141px;
          display: flex;
          flex-direction: column;
          margin-left: 35px;
          margin-top: 25px;
          .first {
            width: 107px;
            height: 141px;
            display: block;
            background: url("https://resource.51beauty.com.cn/1f888ee6-3f5d-4a0e-b8a9-c86a2ecbc51f?imageslim")
            no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            .first-cavity {
              font-size: 15px;
              margin-top: 115px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .make {
    width: 375px;
    height: 70px;
    display: flex;
    align-items: center;
    .make-img {
      width: 375px;
      height: 70px;
      display: flex;
      background: url("https://resource.51beauty.com.cn/396ffc57-c9da-4784-9257-3036101d2c9e?imageslim")
      no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      .make-make {
        font-size: 14px;
        font-weight: 400;
        color: #ffc600;
        margin-left: 24px;
      }
      .make-host {
        font-size: 14px;
        font-weight: 400;
        color: #fff;
      }
      .make-experience {
        width: 100px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        font-size: 14px;
        background: #196ff1;
        margin-left: 28px;
      }
    }
  }
  .tab-first {
    width: 375px;
    height: 420px;
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 325px;
      height: 325px;
      margin-top: 30px;
      margin-bottom: 65px;
    }
  }
  .tab_cur {
    width: 375px;
    height: 41px;
    display: flex;
    font-size: 16px;
    div {
      width: 94px;
      height: 41px;
      border: 1px solid #3784f2;
      text-align: center;
      line-height: 41px;
    }
  }
  .active {
    background: #2278f0;
    color: #fff;
    font-size: 16px;
  }
  .cur_last {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cur_title {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 18px;
    margin-top: 65px;
    margin-bottom: 40px;
  }
  .Marketing {
    width: 349px;
    display: flex;
    align-items: center;
    margin: auto;
    flex-direction: column;
    margin-top: 20px;
  }
  .plan_info {
    width: 349px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .plan_item {
      position: relative;
      margin: 0 1px;
      display: flex;
      justify-content: center;
      .bg_sel {
        width: 349px;
        height: 265px;
        background: url("https://resource.51beauty.com.cn/acbd1e14-ac7b-4974-9e15-d777a6618533?imageslim")
        no-repeat center center;
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: -1;
        opacity: 0;
        transition: all 0.35s;
      }
      .inner_start {
        width: 349px;
        display: flex;
        flex-direction: column;
        margin: auto;
        .inner {
          width: 349px;
          height: 265px;
          background: url("https://resource.51beauty.com.cn/401aeb7f-3a46-4ac2-ab2a-701ff03e2d0b?imageslim")
          no-repeat 0 0;
          background-size: 100% 100%;
          background-size: cover;
          justify-content: flex-start;
          transition: all 0.35s;
          box-shadow: 0px 0px 3px 0px rgba(144, 156, 173, 0.19);
          overflow: hidden;
          border-radius: 3px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 20px;
          margin-top: 20px;
          .icon {
            margin-top: 49px;
            .def,
            .sel {
              width: 44px;
              height: 44px;
              vertical-align: top;
              transition: all 0.35s;
            }
            .def {
              transform: scale(1);
              width: 44px;
              height: 44px;
            }
            .sel {
              width: 44px;
              height: 44px;
              transform: scale(0);
              height: 0;
            }
          }
          .title {
            color: #196ff1;
            font-size: 18px;
            font-weight: 500;
            padding-top: 2px;
            transition: all 0.35s;
            margin-top: 13px;
          }
          .sub_title {
            color: #414141;
            font-size: 12px;
            padding-top: 8px;
            transition: all 0.35s;
            margin-top: 13px;
          }
          .btn_1 {
            color: #196ff1;
            font-size: 12px;
            font-weight: 400;
            // padding-top: 18px;
            transition: all 0.35s;
            cursor: pointer;
            margin-top: 25px;
          }
          .btn_2 {
            width: 105px;
            height: 28px;
            border-radius: 5px;
            background: #2278f0;
            color: #fff;
            font-size: 13px;
            margin-top: 12px;
            transition: all 0.35s;
            cursor: pointer;
            text-align: center;
            line-height: 28px;
          }
        }
      }
    }
    .plan_item:hover {
      .bg_sel {
        opacity: 1;
        width: 349px;
        height: 300px;
      }
      .inner {
        width: 349px;
        height: 265px;
        background: transparent;
        box-shadow: none;
        margin-top: 21px;
        .icon {
          .def {
            width: 44px;
            height: 44px;
            transform: scale(0);
            height: 0;
          }
          .sel {
            transform: scale(1);
            width: 44px;
            height: 44px;
          }
        }
        .title {
          font-size: 18px;
          color: #ffffff;
          margin-top: 10px;
        }
        .sub_title {
          color: #ffffff;
          margin-top: 13px;
        }
        .btn_1 {
          color: #ffffff;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          margin-top: 25px;
        }
        .btn_2 {
          background: #fff;
          color: #2278f0;
          width: 105px;
          height: 28px;
          line-height: 28px;
          font-size: 13px;
          margin-top: 12px;
          margin-bottom: 46px;
        }
      }
    }
  }
}

</style>