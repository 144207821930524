<template>
  <div class="plan">
    <NavBar type="plan" />
    <div class="banner">
      <div class="title">中医行业解决方案</div>
      <div class="subtitle">帮助集团更好的管理员工和客户资源，各分院可以灵活营销和运营自己的客户</div>
      <div class="btn" @click="openAppoint">预约体验</div>
    </div>
    <div class="pain_spot">
      <div class="pain_spot_title">行业痛点</div>
      <div class="pain_spot_content wow slideInUp">
        <div v-for="(item,index) in painSpotList" :key="index">
          <div>
            <img :src="item.img" alt="">
          </div>
          <div class="text">
            <div>{{item.title}}</div>
            <div>{{item.subtitle}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="sub_banner">
      <div class="title">消费医疗行业私域运营白皮书</div>
      <div class="subtitle">您企业的响应速度有多快？这关系到您将如何重塑财务和资产管理，革新客户互动模式，优化供应链和重<br/>新定义劳动力战略。而选择什么样的云应用，将直接影响您的业务敏捷性</div>
      <div class="btn" @click="openAppoint">立即预约</div>
    </div>
    <div class="content_title">中医行业解决方案</div>
    <div class="content">
      <div class="wow slideInUp">
        <div>
          <img src="https://resource.51beauty.com.cn/b8ad2ee6-fc04-447b-ac6d-c265743368b9?imageslim" alt="">
        </div>
        <div>
          <div>全线索管理</div>
          <div>云晰作为腾讯、企业微信、小红书、快手、抖音的战略合作伙伴，可实现公私域无损对接，实现公域到私域的全线索管理</div>
        </div>
      </div>
      <div style="background: #FAFBFD">
        <div class="wow slideInUp">
          <div>流程标准化</div>
          <div>支持标准化服务流程（SOP），通过任务制的方式自行提醒咨询师工作流程，实现高效的客户运营</div>
        </div>
        <div class="wow slideInUp">
          <img src="https://resource.51beauty.com.cn/3db60f85-1143-4fe8-bfee-60fcd7763e4c?imageslim" alt="">
        </div>
        <!--        <p>-->
        <!--          <img src="https://resource.51beauty.com.cn/aca774d8-2132-4dbc-8e6a-f96680bfb0d4?imageslim" alt="">-->
        <!--        </p>-->
      </div>
      <div class="wow slideInUp">
        <div>
          <img src="https://resource.51beauty.com.cn/13c6a71b-deaf-4e7a-a11e-53341bf686e7?imageslim" alt="">
        </div>
        <div>
          <div>高粘性运营</div>
          <div>支持会员体系运营，提高客户粘性，9大营销工具助力客源裂变，并可追踪裂变路径，实现定向业绩增长</div>
        </div>
      </div>
      <div style="background: #FAFBFD">
        <div class="wow slideInUp">
          <div>个性化定制</div>
          <div>支持集团版，包括集团单店版、集团区域连锁版、单店版等，并支持个性化定制，满足不同模式的管理需求</div>
        </div>
        <div class="wow slideInUp">
          <img src="https://resource.51beauty.com.cn/b6181594-e45b-4eec-a9f4-efd152c32472?imageslim" alt="">
        </div>
        <!--        <p>-->
        <!--          <img src="https://resource.51beauty.com.cn/aca774d8-2132-4dbc-8e6a-f96680bfb0d4?imageslim" alt="">-->
        <!--        </p>-->
      </div>
    </div>
    <!-- 专注消费医疗 -->
    <Bottom />
    <appointment
        :visibleDialog="appointmentVisible"
        @closeAppoint="closeAppoint"
    />
    <Contact/>
  </div>
</template>
<script>
import NavBar from "@/components/navBar.vue";
import Bottom from "@/components/bottom.vue";
import Appointment from "@/components/appointment.vue";
import Contact from "@/components/contact.vue";
import { WOW } from 'wowjs'

export default {
  components: {
    NavBar,
    Bottom,
    Appointment,
    Contact
  },
  data() {
    return {
      painSpotList:[
        {
          img:'https://resource.51beauty.com.cn/0f5a19cd-b703-4051-9c4b-aead16509b53?imageslim',
          title: '线索转化成本高',
          subtitle: '流量资源缺乏精度，且缺少数据反馈，投放成本居高不下'
        },
        {
          img:'https://resource.51beauty.com.cn/98beecdb-dbd8-430e-afdc-7175504269ee?imageslim',
          title: '服务流程不统一',
          subtitle: '缺乏服务标准化流程，导致回访、再邀约、二次开单效率低'
        },
        {
          img:'https://resource.51beauty.com.cn/8a211e79-e1d8-4c1e-9073-63c66b00c433?imageslim',
          title: '老客带新占比低',
          subtitle: '缺乏裂变工具，老客带新率太低'
        },
        {
          img:'https://resource.51beauty.com.cn/61c810ae-e0aa-43b0-81c9-1e352a9450e8?imageslim',
          title: '连锁模式管理难',
          subtitle: '集团连锁模式管理难，数据权限模糊，难以实现统一有效管理'
        },
      ],
      appointmentVisible:false
    };
  },
  mounted() {
    new WOW().init()
  },
  methods: {
    // 关闭预约弹层
    closeAppoint (e) {
      this.appointmentVisible = e
    },
    // 打开预约弹层
    openAppoint () {
      this.appointmentVisible = true
    },
  },
};
</script>
<style scoped lang="less">
.plan{
  .banner{
    background: url("https://resource.51beauty.com.cn/92ae0be6-580b-4e23-b112-1e10e9128223?imageslim") no-repeat;
    background-size: cover;
    width: 375px;
    height: 94px;
    .title{
      padding: 27px 0 0 70px;
      font-weight: bold;
      font-size: 10px;
    }
    .subtitle{
      width: 142px;
      margin-left: 70px;
      margin-top: 4px;
      color: #273755;
      font-size: 4px;
      line-height: 7px;
    }
    .btn{
      background: #2C68FF;
      font-size: 4px;
      margin-top: 11px;
      height: 10px;
      width: 33px;
      margin-left: 70px;
      border-radius: 1px;
      line-height: 10px;
      text-align: center;
      color: #fff;
      cursor: pointer;
    }
  }
  .pain_spot{
    width: 235px;
    margin: 0 auto;
    margin-top: 27px;
    .pain_spot_title{
      font-size: 8px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 12px;
    }
    >.pain_spot_content{
      padding-bottom: 24px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      >div{
        width: 115px;
        height: 39px;
        display: flex;
        align-items: center;
        box-shadow: 0px 2px 4px 0px rgba(55,99,170,0.1);
        border-radius: 2px;
        padding: 10px 0;
        margin-bottom: 5px;
        img{
          width: 18px;
          height: 18px;
          display: block;
          margin-left: 10px;
        }
        .text{
          margin-left: 7px;
          width: 75px;
          >div:nth-child(1){
            margin-bottom: 4px;
            font-size: 4px;
            font-weight: bold;
          }
          >div:nth-child(2){
            font-size: 2.8px;
            color: #273755;
            line-height: 5px;
          }
        }
      }
    }
  }
  .sub_banner{
    background: url("https://resource.51beauty.com.cn/78ec4dfa-9b8a-430f-a937-1f715523727f?imageslim") no-repeat;
    background-size: cover;
    width: 375px;
    height: 82px;
    .title{
      font-size: 6px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      padding-top: 16px;
    }
    .subtitle{
      font-size: 3px;
      color: #fff;
      margin-top: 8px;
      text-align: center;
      opacity: .8;
    }
    .btn{
      width: 33px;
      height: 10px;
      background: #2C68FF;
      box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
      border-radius: 1px;
      color: #fff;
      font-size: 4px;
      text-align: center;
      line-height: 10px;
      margin: 0 auto;
      margin-top: 14px;
      cursor: pointer;
    }
  }
  .content_title{
    font-size: 8px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0 9px 0
  }
  .content{
    >div{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 28px;
      position: relative;
      padding-top: 20px;
      img{
        width: 117px;
        display: block;
      }
      >div:nth-child(2){
        margin-left: 10px;
      }
      >div>div:nth-child(1){
        font-size: 5px;
        font-weight: bold;
      }
      >div>div:nth-child(2){
        font-size: 4px;
        color: #273755;
        margin-top: 4px;
        width: 113px;
      }
      >p{
        position: absolute;
        right: 5px;
        bottom: 0;
        margin: 0;
        img{
          width: 131px;
          height: 54px;
          display: block;
        }
      }
    }
  }
}
</style>