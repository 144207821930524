<template>
    <div>
        <a-modal
            :visible="visibleDialog"
            :footer="null"
            :layout="formLayout"
            :closable="false"
            :maskClosable="true"
            @cancel="close">
            <div class="inner form_appointment">
                <div class="banner">
                    <img src="https://resource.51beauty.com.cn/16bf620a-e3a8-428b-9654-4a0f2f83d88a?imageslim" />
                </div>
                <div class="form">
                    <div class="form_title"></div>
                    <a-form-model :model="form"  ref="ruleForm">
                        <a-form-model-item prop="name">
                            <div class="input_flex">
                                <div class="input_title">姓名<span>*</span></div>
                                <div><a-input placeholder="请输入您的姓名" v-model="form.name" /></div>
                            </div>
                        </a-form-model-item>
                        <a-form-model-item prop="phone">
                            <div class="input_flex">
                                <div class="input_title">手机号<span>*</span></div>
                                <div><a-input placeholder="请输入您的手机号码" v-model="form.phone"/></div>
                            </div>
                        </a-form-model-item>
                        <a-form-model-item prop="tenantName">
                            <div class="input_flex">
                                <div class="input_title">机构名称<span>*</span></div>
                                <div><a-input placeholder="请输入您的机构名称" v-model="form.tenantName"/></div>
                            </div>
                        </a-form-model-item>
                        <a-form-model-item prop="industry">
                            <div class="input_flex">
                                <div class="input_title">所属行业</div>
                                <div>
                                    <a-select v-model="form.industry" size="small" placeholder="请选择行业">
                                        <img class="select_icon" src="https://resource.51beauty.com.cn/91a1612e-2273-47dd-ace4-7650a842fdbd?imageslim" slot="suffixIcon" />
                                        <a-select-option :value="1">医美</a-select-option>
                                        <a-select-option :value="2">口腔</a-select-option>
                                        <a-select-option :value="3">眼科</a-select-option>
                                        <a-select-option :value="4">植发</a-select-option>
                                        <a-select-option :value="5">妇产</a-select-option>
                                        <a-select-option :value="6">产康</a-select-option>
                                    </a-select>
                                </div>
                            </div>

                        </a-form-model-item>
                        <a-form-model-item prop="district">
                            <div class="input_flex">
                                <div class="input_title">所属地区</div>
                                <div>
                                    <a-cascader v-model="form.district" placeholder="请选择所属地区" :options="residences" :field-names="{ label: 'label', value: 'label', children: 'children' }">
                                        <img class="select_icon" src="https://resource.51beauty.com.cn/91a1612e-2273-47dd-ace4-7650a842fdbd?imageslim" slot="suffixIcon" />
                                    </a-cascader>
                                </div>
                            </div>
                        </a-form-model-item>
                        <div class="tips">{{tipsText}}</div>
                    </a-form-model>
                    <div class="btn flex">
                        <div class="btn_clone" @click="close">取消</div>
                        <div class="btn_submit" @click="handleSubmit">提交</div>
                    </div>

                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import addressData from '../util/addressData'

export default {
    name: 'Appointment',
    props: {
        visibleDialog: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            formLayout: 'horizontal',
            form: {
                type: 1, // 预约类型（1：官网， 2：公众号）
                name: '', // 姓名
                phone: '', // 手机号
                tenantName: '', // 机构名称
                industry: undefined, // 行业
                district: undefined // 地区
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入您的手机号', trigger: 'blur' }
                ],
                tenantName: [
                    { required: true, message: '请输入您的机构名称', trigger: 'blur' }
                ],
                industry: [
                    { required: true, message: '请选择行业', trigger: 'change' }
                ],
                district: [
                    { required: true, message: '请选择您所在地区', trigger: 'change' }
                ]
            },
            residences: [],
            tipsText: ''
        }
    },
    mounted() {
        this.residences = addressData
    },
    methods: {
        // 提交预约表单
        handleSubmit () {
            if(this.form.name==''){
                this.tipsText = '请输入您的姓名'
                return
            }
            if(this.form.phone==''){
                this.tipsText = '请输入您的手机号'
                return
            }
            if(this.form.tenantName==''){
                this.tipsText = '请输入您的机构名称'
                return
            }
                let obj = JSON.parse(JSON.stringify(this.form))
                if(this.form.district){
                    obj.district = this.form.district.join('/')
                }
                this.$api.post('/business/AppointmentController/saveAppointment', obj, () => {
                    this.close();
                    window._agl && window._agl.push(['track', ['success', {t: 3}]])
                });
        },
        initForm () {
            this.form = {
                name: '', // 姓名
                phone: '', // 手机号
                tenantName: '', // 机构名称
                industry: undefined, // 行业
                district: undefined // 地区
            }
        },
        // 关闭预约弹层
        close () {
            this.initForm();
            this.$emit('closeAppoint', false)
        }
    }
}
</script>
<style lang="less" scoped>
    /deep/.ant-modal-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    /deep/.ant-message-notice-content span{
        font-size: 3px !important;
    }
    .select_icon{
        width: 4px;
        height: 4px;
        display: block;
    }
    .tips{
        font-size: 3px;
        color: #FF0000;
        margin-top: 1px;
    }
    /deep/.ant-modal{
        width: 195px !important;
        padding-bottom: 0;
        position: static;
    }
    /deep/.ant-modal-body{
        padding: 0;
        position: relative;
    }
    /deep/.ant-modal-content{
        border-radius: 0px;
        box-shadow: none;
        background-color: transparent;
    }
    .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active{
        border-right-width: 0px !important;
        /*box-shadow: 0 0 0 0.2px rgb(24, 144, 255, 0.1) !important;*/
    }
    .inner{
        width: 195px;
        background: #fff;
        display: flex;
        border-radius: 3px;
        position: relative;
        margin: 0 auto;
        .banner{
            width: 98px;
            img{
                width: 98px;
                height: auto;
                vertical-align: top;
                display: block;
            }
        }
        .form{
            padding: 6px 0px 0;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .form_title{
                text-align: center;
                font-size: 5px;
                font-weight: bold;
                height: 2px;
            }
            .ant-form-item{
                margin-bottom: 1px !important;
            }
            .input_flex{
                display: flex;
                align-items: center;
                width: 78px;
                border-bottom: #EAEDF1 .3px solid;
                .input_title{
                    font-size: 3px;
                    color: #273755;
                    width: 15px;
                    display: flex;
                    align-items: center;
                    margin-top: .7px;
                    span{
                        color: #FF0000;
                        display: block;
                    }
                }
            }
            .ant-input{
                width: 62px;
                height: 8px !important;
                border: none;
                border-radius: 2px;
                font-size: 3px !important;
            }
            .ant-input:hover{
                border-right-width: 0.5px !important;
            }
            .ant-input:focus{
                border-right-width: 0px !important;
                border-color: #000 !important;
                border: none !important;
                box-shadow: none !important;
            }
            .btn{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 0 8px;
                .btn_clone{
                    width: 35px;
                    height: 10px;
                    border-radius: 1px;
                    border: .3px solid #EAEDF1;
                    text-align: center;
                    line-height: 10px;
                    font-size: 3px;
                    color: #273755;
                    cursor:pointer;

                }
                .btn_submit{
                    width: 35px;
                    height: 10px;
                    background: #2C68FF;
                    border-radius: 1px;
                    text-align: center;
                    line-height: 10px;
                    font-size: 3px;
                    color: #fff;
                    cursor:pointer;

                }
            }
        }
    }

    /deep/.ant-dorm{
        line-height: normal;
    }
    /deep/.ant-form label{
        font-size: 4px;
        color: #333;
    }
    /deep/.ant-form{
        height:60px;
    }
</style>

<style lang="less">
    .ant-message-notice-content{
        padding: 3px 6px;
        font-size: 8px;
        border-radius: 3px;
        span{
            font-size: 6px;
        }
    }
    .ant-message .anticon{
        font-size: 8px;
        margin-right: 3px;
    }
    .ant-select-dropdown{
        width: 60px !important;
        border-radius: 2px;
        .ant-select-dropdown-menu{
            padding: 2px 0!important;
        }
        .ant-select-dropdown-menu-item{
            font-size: 3px !important;
            line-height: 6px;
            padding: 2px;

        }
    }
    .ant-cascader-menus{
        height: auto;
        border-radius: 2px;
        .ant-cascader-dropdown-menu{
            padding: 2px 0!important;
        }
        .ant-cascader-menu-item{
            width: 100%;
            font-size: 3px !important;
            line-height: 6px;
            padding: 2px;
        }
        .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon, .ant-cascader-menu-item-loading-icon{
            font-size: 3px !important;
            right: 6px;
        }
    }
    .ant-cascader-picker-clear{
        width: 10px;
        height: 10px;
        font-size: 5px;
        top: 7.5px;
    }
    .ant-cascader-menus ul, .ant-cascader-menus ol{
        min-width: 50px;
        height: 60px;
    }
    .ant-form-explain, .ant-form-extra{
        font-size: 3px;
        min-height: 0;
        height: auto;
        line-height: 5px;
        margin-top: 0;
        margin-bottom: 0;
    }
    .form_appointment{
        .ant-form-item-control{
            line-height: 5px !important;
        }
        .ant-input{
            border: 0.3px solid #999;
            font-size: 4px;
            padding: 6px;
            border-radius: 2px;
            height: 12px;
        }
        .ant-cascader-picker{
            line-height: 0;
        }
        .ant-cascader-input{
            border: none !important;
            width: 62px;
            font-size: 3px !important;
        }
        .ant-cascader-picker:focus .ant-cascader-input{
            border-right-width: 0px !important;
            box-shadow: 0 0 0 0px rgb(24, 144, 255, 0.1) !important;
        }
        .ant-cascader-picker-arrow{
            font-size: 4px;
            top: 10px !important;
            right: 2px !important;
            height: 4px !important;
            width: 4px !important;
            transform: rotate(0deg);
        }
        .ant-cascader-picker-label{
            padding: 0 6px;
            font-size: 3px !important;
        }
        .ant-select-selection{
            border:none !important;
            width: 62px;
            font-size: 3px !important;
            padding: 0px !important;
            border-radius: 2px;
            box-shadow: none !important;
        }
        .ant-select-selection__rendered{
            line-height: 5px !important;
            margin: 0;
            position: absolute !important;
            top: 3px !important;
            left: 6px;
            width: 100%;
        }
        .ant-select-selection--single{
            height: 11px;
        }
        .ant-select-selection__placeholder, .ant-select-search__field__placeholder{
            height: 13px;
        }
        .ant-select-arrow{
            font-size: 4px;
            right: 2px !important;
            top: 9px !important;
        }
    }
</style>
