<template>
  <div class="plan">
    <NavBar type="plan" />
    <div class="banner">
      <div class="title">眼科行业解决方案</div>
      <div class="subtitle">帮助集团更好的管理员工和客户资源，各分院可以灵活营销和运营自己的客户</div>
      <div class="btn" @click="openAppoint">预约体验</div>
    </div>
    <div class="pain_spot">
      <div class="pain_spot_title">行业痛点</div>
      <div class="pain_spot_content wow slideInUp">
        <div v-for="(item,index) in painSpotList" :key="index">
          <div>
            <img :src="item.img" alt="">
          </div>
          <div class="text">
            <div>{{item.title}}</div>
            <div>{{item.subtitle}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="sub_banner">
      <div class="title">消费医疗行业私域运营白皮书</div>
      <div class="subtitle">您企业的响应速度有多快？这关系到您将如何重塑财务和资产管理，革新客户互动模式，优化供应链和重<br/>新定义劳动力战略。而选择什么样的云应用，将直接影响您的业务敏捷性</div>
      <div class="btn" @click="openAppoint">立即预约</div>
    </div>
    <div class="content_title">眼科行业解决方案</div>
    <div class="content">
      <div class="wow slideInUp">
        <div>
          <img src="https://resource.51beauty.com.cn/59a93124-a79e-411d-8dd6-e8ca14fb18e2?imageslim" alt="">
        </div>
        <div>
          <div>高效管理线索</div>
          <div>云晰SCRM实现对客户线索的高效管理，杜绝客户线索无效浪费</div>
        </div>
      </div>
      <div style="background: #FAFBFD">
        <div class="wow slideInUp">
          <div>快速获客分享</div>
          <div>咨询师可使用聊天辅助专业且高效的沟通，一键进行快速获客分享</div>
        </div>
        <div class="wow slideInUp">
          <img src="https://resource.51beauty.com.cn/5e2a1ee4-7f99-4b5d-90d3-59fcbc8de05d?imageslim" alt="">
        </div>
<!--        <p>-->
<!--          <img src="https://resource.51beauty.com.cn/aca774d8-2132-4dbc-8e6a-f96680bfb0d4?imageslim" alt="">-->
<!--        </p>-->
      </div>
      <div class="wow slideInUp">
        <div>
          <img src="https://resource.51beauty.com.cn/3d32e474-4435-430c-8ee1-82c279ea87ac?imageslim" alt="">
        </div>
        <div>
          <div>掌握客户意向</div>
          <div>可快速查看客户轨迹，掌握客户行为意向，进行针对性跟进，快速邀约到院</div>
        </div>
      </div>
      <div style="background: #FAFBFD">
        <div class="wow slideInUp">
          <div>协同服务客户</div>
          <div>云晰已有成熟的系统打通方案，具有实际对接经验，可全面对接院内，实现机构对客户信息的一体化识别</div>
        </div>
        <div class="wow slideInUp">
          <img src="https://resource.51beauty.com.cn/98f5f501-87db-4e92-9c53-08ef905f67af?imageslim" alt="">
        </div>
        <!--        <p>-->
        <!--          <img src="https://resource.51beauty.com.cn/aca774d8-2132-4dbc-8e6a-f96680bfb0d4?imageslim" alt="">-->
        <!--        </p>-->
      </div>
    </div>
    <!-- 专注消费医疗 -->
    <Bottom />
    <appointment
            :visibleDialog="appointmentVisible"
            @closeAppoint="closeAppoint"
    />
    <Contact/>
  </div>
</template>
<script>
  import NavBar from "@/components/navBar.vue";
  import Bottom from "@/components/bottom.vue";
  import Appointment from "@/components/appointment.vue";
  import Contact from "@/components/contact.vue";
  import { WOW } from 'wowjs'

  export default {
    components: {
      NavBar,
      Bottom,
      Appointment,
      Contact
    },
    data() {
      return {
        painSpotList:[
          {
            img:'https://resource.51beauty.com.cn/bf866448-835b-43ba-badd-dabe6b8a0a59?imageslim',
            title: '沟通难',
            subtitle: '咨询师在沟通过程中缺乏数字化的辅助工具，沟通方式单一，缺乏吸引力'
          },
          {
            img:'https://resource.51beauty.com.cn/1985a2d6-7432-4508-be59-e51b374d4a76?imageslim',
            title: '转化差',
            subtitle: '传统眼科机构是人工随机分配客户，导致客户资源分配不均，造成频繁的客户资源浪费'
          },
          {
            img:'https://resource.51beauty.com.cn/d019f003-c784-457f-94bf-ba2d8616b036?imageslim',
            title: '到院少',
            subtitle: '咨询师缺乏精细化运营客户的工具，转化效率低'
          },
          {
            img:'https://resource.51beauty.com.cn/65172855-1c81-4489-bd6c-cb4d17dea661?imageslim',
            title: '系统多',
            subtitle: '院内系统之间无法打通，各系统之间客户数据多而复杂'
          },
        ],
        appointmentVisible:false
      };
    },
    mounted() {
      new WOW().init()
    },
    methods: {
      // 关闭预约弹层
      closeAppoint (e) {
        this.appointmentVisible = e
      },
      // 打开预约弹层
      openAppoint () {
        this.appointmentVisible = true
      },
    },
  };
</script>
<style scoped lang="less">
  .plan{
    .banner{
      background: url("https://resource.51beauty.com.cn/6152ef82-bac0-4590-ab30-1b8de4eced80?imageslim") no-repeat;
      background-size: cover;
      width: 375px;
      height: 94px;
      .title{
        padding: 27px 0 0 70px;
        font-weight: bold;
        font-size: 10px;
      }
      .subtitle{
        width: 142px;
        margin-left: 70px;
        margin-top: 4px;
        color: #273755;
        font-size: 4px;
        line-height: 7px;
      }
      .btn{
        background: #2C68FF;
        font-size: 4px;
        margin-top: 11px;
        height: 10px;
        width: 33px;
        margin-left: 70px;
        border-radius: 1px;
        line-height: 10px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
    }
    .pain_spot{
      width: 235px;
      margin: 0 auto;
      margin-top: 27px;
      .pain_spot_title{
        font-size: 8px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 12px;
      }
      >.pain_spot_content{
        padding-bottom: 24px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        >div{
          width: 115px;
          height: 39px;
          display: flex;
          align-items: center;
          box-shadow: 0px 2px 4px 0px rgba(55,99,170,0.1);
          border-radius: 2px;
          padding: 10px 0;
          margin-bottom: 5px;
          img{
            width: 18px;
            height: 18px;
            display: block;
            margin-left: 10px;
          }
          .text{
            margin-left: 7px;
            width: 75px;
            >div:nth-child(1){
              margin-bottom: 4px;
              font-size: 4px;
              font-weight: bold;
            }
            >div:nth-child(2){
              font-size: 2.8px;
              color: #273755;
              line-height: 5px;
            }
          }
        }
      }
    }
    .sub_banner{
      background: url("https://resource.51beauty.com.cn/78ec4dfa-9b8a-430f-a937-1f715523727f?imageslim") no-repeat;
      background-size: cover;
      width: 375px;
      height: 82px;
      .title{
        font-size: 6px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        padding-top: 16px;
      }
      .subtitle{
        font-size: 3px;
        color: #fff;
        margin-top: 8px;
        text-align: center;
        opacity: .8;
      }
      .btn{
        width: 33px;
        height: 10px;
        background: #2C68FF;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
        border-radius: 1px;
        color: #fff;
        font-size: 4px;
        text-align: center;
        line-height: 10px;
        margin: 0 auto;
        margin-top: 14px;
        cursor: pointer;
      }
    }
    .content_title{
      font-size: 8px;
      font-weight: bold;
      text-align: center;
      margin: 20px 0 9px 0
    }
    .content{
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 28px;
        position: relative;
        padding-top: 20px;
        img{
          width: 117px;
          display: block;
        }
        >div:nth-child(2){
          margin-left: 10px;
        }
        >div>div:nth-child(1){
          font-size: 5px;
          font-weight: bold;
        }
        >div>div:nth-child(2){
          font-size: 4px;
          color: #273755;
          margin-top: 4px;
          width: 113px;
        }
        >p{
          position: absolute;
          right: 5px;
          bottom: 0;
          margin: 0;
          img{
            width: 131px;
            height: 54px;
            display: block;
          }
        }
      }
    }
  }
</style>