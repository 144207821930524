<template>
  <div class="advertising">
    <NavBar1 type="product" />
<!--    <NavBar2 v-if="scrollTop>0" type="product" />-->
    <div class="banner">
      <div class="title">私域直播</div>
      <div class="subtitle">满足医疗行业专业性要求，私域直播带货加私域直播科普一体化</div>
      <div class="btn" @click="openAppoint">预约体验</div>
    </div>
    <div class="content">
      <div class="wow slideInUp">
        <div>
          <img src="https://resource.51beauty.com.cn/6974ec47-7dd9-456b-aafc-c379ccadf70a?imageslim" alt="">
        </div>
        <div>
          <div>私域直播业绩增长</div>
          <div>云晰为客户提供完整的一整套直播服务，包括直播科技软件产品（云晰有直播牌照）、策划、社群运营、设备、主持人、导演、场控等全部都是由云晰提供的服务，快速帮助客户做老客户激活、新客户裂变成交，做业绩增长</div>
        </div>
      </div>
      <div style="background: #FAFBFD">
        <div class="wow slideInUp">
          <div>私域直播科普宣教</div>
          <div>客户使用的云晰直播产品，可以最大化降低公域平台监管的前提下针对不同单病种做科普直播，加强对患者的教育，且可以做到日常化直播</div>
        </div>
        <div class="wow slideInUp">
          <img src="https://resource.51beauty.com.cn/acaba8ce-5526-4df0-bb3e-155ad8865c94?imageslim" alt="">
        </div>
        <p>
          <img src="https://resource.51beauty.com.cn/aca774d8-2132-4dbc-8e6a-f96680bfb0d4?imageslim" alt="">
        </p>
      </div>
      <div class="wow slideInUp">
        <div>
          <img src="https://resource.51beauty.com.cn/5cd5802e-9593-4a6c-83ae-717a1b017b7e?imageslim" alt="">
        </div>
        <div>
          <div>私域直播最佳实践</div>
          <div>服务了众多世界500强、医疗上市公司、区域特色医疗品牌，包括口腔、眼科、中医、医美、慢病管理、高端辅助生殖行业，几百万到上千万业绩不等，广受好评.</div>
        </div>
      </div>
    </div>
    <div class="exhibition">
      <div class="exhibition_con">
        <div>新闻与活动</div>
        <div class="wow slideInUp">
          <div class="exhibition_item" @click="article('https://cloud.tencent.com/developer/article/1892234?from=article.detail.1902177')">
            <div class="exhibition_img">
              <img src="https://resource.51beauty.com.cn/f17ab896-e595-4a3e-bda8-7258a73e1e48?imageslim" alt="">
            </div>
            <div class="exhibition_text">
              <div class="title">腾讯SaaS加速器三期总决选</div>
              <div class="subtitle">云晰科技从1100家报名SaaS企业中顺利突围，获得最终角逐Top 40席位的入场券。</div>
            </div>
          </div>
          <div class="exhibition_item" @click="article('https://cloud.tencent.com/document/product/306/47166')">
            <div class="exhibition_img">
              <img src="https://resource.51beauty.com.cn/a5826992-7c89-4adf-bbca-c986ca84e718?imageslim" alt="">
            </div>
            <div class="exhibition_text">
              <div class="title">云晰科技已通过腾讯云千帆计划复选</div>
              <div class="subtitle">营销云产品（医美版、口腔版、眼科版）已上架腾讯云市场，成为“千帆甄选”推荐产品。</div>
            </div>
          </div>
          <div class="exhibition_item" @click="article('https://baijiahao.baidu.com/s?id=1718001203675989497&wfr=spider&for=pc')">
            <div class="exhibition_img">
              <img src="https://resource.51beauty.com.cn/4bc47af4-78d0-4a64-95b3-be41e94b212e?imageslim" alt="">
            </div>
            <div class="exhibition_text">
              <div class="title">云晰科技和企业微信官方合作组织沙龙会</div>
              <div class="subtitle">云晰与企业微信医疗板块深度合作共创，将企微作为腾讯新流量生态的结合点，帮助消费医疗…</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- 专注消费医疗 -->
    <Bottom />
    <appointment
            :visibleDialog="appointmentVisible"
            @closeAppoint="closeAppoint"
    />
    <Contact />
  </div>
</template>
<script>
  import NavBar1 from "@/components/navBarProduct.vue";
  // import NavBar2 from "@/components/navBar.vue";
  import Bottom from "@/components/bottom.vue";
  import Appointment from "@/components/appointment.vue";
  import Contact from '@/components/contact.vue'

  import { WOW } from 'wowjs'
  export default {
    components: {
      NavBar1,
      // NavBar2,
      Bottom,
      Appointment,
      Contact
    },
    data() {
      return {
        scrollTop:0,
        appointmentVisible:false
      };
    },
    mounted() {
      new WOW().init()
      window.addEventListener('scroll',this.handleScroll,true)
    },
    methods: {
      // 关闭预约弹层
      closeAppoint (e) {
        this.appointmentVisible = e
      },
      // 打开预约弹层
      openAppoint () {
        this.appointmentVisible = true
      },
      handleScroll(){
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        this.scrollTop = scrollTop
      },
      article(href){
        window.location = href
      }
    },
  };
</script>
<style scoped lang="less">
  .advertising{
    .banner{
      background: url("https://resource.51beauty.com.cn/c9b9e6a7-004d-487c-886d-e01697fa4d34?imageslim") no-repeat;
      background-size: cover;
      width: 375px;
      height: 94px;
      .title{
        font-size: 10px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        padding-top: 27px;
      }
      .subtitle{
        font-size: 6px;
        color: #fff;
        margin-top: 3px;
        text-align: center;
      }
      .btn{
        width: 33px;
        height: 10px;
        background: #FFFFFF;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
        border-radius: 1px;
        color: #2C68FF;
        cursor:pointer;
        font-size: 4px;
        text-align: center;
        line-height: 10px;
        margin: 0 auto;
        margin-top: 16px;
      }
    }
    .content{
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 28px 0;
        position: relative;
        z-index: 2;
        img{
          width: 117px;
          display: block;
        }
        >div:nth-child(2){
          margin-left: 10px;
        }
        >div>div:nth-child(1){
          font-size: 5px;
          font-weight: bold;
        }
        >div>div:nth-child(2){
          font-size: 4px;
          color: #273755;
          margin-top: 4px;
          width: 113px;
        }
        >p{
          position: absolute;
          right: 5px;
          bottom: 0;
          margin: 0;
          z-index: -1;
          img{
            width: 131px;
            height: 54px;
            display: block;
          }
        }
      }
    }
    .exhibition{
      background: #FAFBFD;
      padding-bottom: 29px;
      .exhibition_con{

        >div:nth-child(1){
          font-size: 8px;
          font-weight: bold;
          text-align: center;
          padding: 29px 0 12px 0;
        }
        >div:nth-child(2){
          width: 235px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          .exhibition_item{
            width: 73px;
            height: 81px;
            background: #fff;
            cursor: pointer;
            transition: .3s;
            border-radius: 3px;
            .exhibition_img{
              height: 55px;
              background: #FFFFFF;
              margin: 0 auto;
              img{
                width: 59px;
                margin: 0 auto;
                margin-top: 4px;
                display: block;
              }
              box-shadow: 0px 2px 4px 0px rgba(55,99,170,0.06);
            }
            >.exhibition_text{
              background: #fff;
              width: 100%;
              height: 26px;
              margin-top: .3px;
              box-shadow: 0px 2px 4px 0px rgba(55,99,170,0.06);
              border-radius: 0px 0px 3px 3px;
              >div:nth-child(1){
                font-weight: bold;
                font-size: 3px;
                margin-left: 5px;
                padding-top: 5px;
              }
              >div:nth-child(2){
                padding: 0 5px;
                margin-top: 2px;
                color: #273755;
                font-size: 3px;
                overflow:hidden;
                text-overflow:ellipsis;
                display:-webkit-box;
                -webkit-box-orient:vertical;
                -webkit-line-clamp:2;
              }
            }
          }
          .exhibition_item:hover{
            transform: scale(1.1);
          }
        }
      }
    }
  }
</style>