<template>
    <div>
        <div class="home">
            <NavBar type="home"/>
            <div class="content">
                <div class="banner">
                    <div class="banner_info">
                        <div class="banner_title" >
                            <div class="title">云晰科技</div>
                            <div class="last_title">专注医疗服务业绩增长</div>
                        </div>
                    </div>
                </div>

                <div class="info flex_column">
                    <div class="title wow slideInUp">
                        <div class="first_title">消费医疗产品手册</div>
                        <div class="last_title">一站式数字化业绩增长解决方案</div>
                    </div>

                    <div class="product_list_con flex">
                        <div class="product_item wow slideInUp" v-for="(item, index) in product" :key="index" @click="skip(item.path)">
                            <div class="icon">
                                <img :src="item.icon"/>
                            </div>
                            <div class="title">{{item.title}}</div>
                            <div class="sub_title">
                                <div>{{item.subtitle1}}</div>
                                <div>{{item.subtitle2}}</div>
                                <div>{{item.subtitle3}}</div>
                            </div>
                            <div class="next">
                                查看更多
                                <img src="https://resource.51beauty.com.cn/ca33ce9b-c56c-4584-be1c-067f682085e3?imageslim" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="info flex_column tab_bolck">
                    <div class="title wow slideInUp">
                        <div class="first_title">行业解决方案</div>
                        <div class="last_title">为您的机构量身定制整体解决方案，助您高效转化</div>
                    </div>
                    <div class="tab_box">
                        <div class="tab_item" v-for="(item, index) in tabList" :key="index" :class="{'tab_active': item.value === curTab}" @click="setTab(item.value)">
                            <div>{{item.name}}</div>
                            <div class="line"  :style="item.value === curTab?'opacity: 1':'opacity: 0'"></div>
                        </div>
                    </div>
                    <div class="segmentation"></div>
                    <div class="tab_content">
                        <div class="tab_content_left wow slideInUp" data-wow-duration="1s">
                            <img :src="plans[curTab].img" alt="">
                        </div>
                        <div class="tab_content_right wow slideInUp">
                            <div class="tab_content_right_title">{{plans[curTab].title}}</div>
                            <div class="tab_content_right_subtitle">{{plans[curTab].subtitle}}</div>
                            <div class="tab_content_right_list">{{plans[curTab].con1}}</div>
                            <div class="tab_content_right_list">{{plans[curTab].con2}}</div>
                            <div class="tab_content_right_list">{{plans[curTab].con3}}</div>
                            <div class="tab_content_right_list">{{plans[curTab].con4}}</div>
                            <div class="tab_content_right_btn" @click="skip(plans[curTab].path)">了解详情</div>
                        </div>
                    </div>
<!--                    <div class="tab_img">-->
<!--                        <img v-show="curTab === 0" :src="tabImgs[0]" />-->
<!--                        <img v-show="curTab === 1" :src="tabImgs[1]" />-->
<!--                        <img v-show="curTab === 2" :src="tabImgs[2]" />-->
<!--                        <img v-show="curTab === 3" :src="tabImgs[3]" />-->
<!--                    </div>-->
                </div>

                <div class="info flex_column">
                    <div class="title wow slideInUp">
                        <div class="first_title">部分客户</div>
                        <div class="last_title">口腔、眼科、中医、医美等主要合作机构展示</div>
                    </div>
                    <div class="logo_content">
                        <div class="wow slideInUp" v-for="(item,index) in logoList" :key="index">
                            <img :src="item" alt="">
                        </div>
                    </div>
                </div>


                <div class="doctor">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="doctor_content wow slideInUp">
                                    <div class="doctor_introduce">云晰SaaS给我们提供了一个专业的消费医疗SaaS平台，于是我们搭建起了属于自己的数字化系统。刚开始的时候只是在小程序上做了一场私域直播的活动，主动的去进行分享朋友圈和转发给客户，没想到的是，直播一小时内带来33单在线下单，5天内为我们带来了1600次的访问量，所有线索中34%是直接获取到手机号的高意向客户。而这次的直播活动仅仅是云晰SaaS提供的9大营销活动功能中的一个，我坚信这样的数字化工具是医美真正的获客之道。</div>
                                    <div class="doctor_info">
                                        <div>
                                            <img src="https://resource.51beauty.com.cn/4e77c997-fe44-4afa-b5c7-2ac4452724e3?imageslim" alt="">
                                        </div>
                                        <div>
                                            <div>张院长</div>
                                            <div>北京某某机构院长</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="doctor_content wow slideInUp">
                                    <div class="doctor_introduce">使用云晰SaaS将公众号链接小程序后，我们将非常有信心持续这样运行。随着时间推移，获客成本还将进一步降低，同时我们的员工还能更有效率地及时跟单、邀约成交。</div>
                                    <div class="doctor_info">
                                        <div>
                                            <img src="https://resource.51beauty.com.cn/8a00cc9a-833e-44d3-a01e-859cc17c7e2e?imageslim" alt="">
                                        </div>
                                        <div>
                                            <div>洪院长</div>
                                            <div>某知名口腔医院院长</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="doctor_content wow slideInUp">
                                    <div class="doctor_introduce">我们已经通过云晰SaaS更好地管理了咨询师个微的客群，咨询师的业绩和积极性都在提升。目前已经在考虑升级到企微，因为只有企微能做到真正的客户留存。云晰SaaS的企微SCRM与智能工作台也是打通的，既能有效利用小程序组件的丰富能力，又保持了咨询师一对一的客户所属关系，所以我们会继续选择云晰SaaS。</div>
                                    <div class="doctor_info">
                                        <div>
                                            <img src="https://resource.51beauty.com.cn/9786b4b1-dc54-41cb-897d-9bb1318e96d0?imageslim" alt="">
                                        </div>
                                        <div>
                                            <div>刘院长</div>
                                            <div>成都综合医美机构院长</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="quotes_left">
                            <img src="https://resource.51beauty.com.cn/b59bfb5c-fbcb-4011-9b71-4c8a7776333d?imageslim" alt="">
                        </div>
                        <div class="quotes_right">
                            <img src="https://resource.51beauty.com.cn/f2ae2c4b-67d0-41f9-96a6-39a8c7ffa6b5?imageslim" alt="">
                        </div>
                    </div>
                    <div class="swiper_paging">
                        <div :style="swiperCur==0?'background:#fff':''" @click="toSwiper('0')"></div>
                        <div :style="swiperCur==1?'background:#fff':''" @click="toSwiper('1')"></div>
                        <div :style="swiperCur==2?'background:#fff':''" @click="toSwiper('2')"></div>
                    </div>
                </div>

<!--                <div class="contact">-->
<!--                    <div class="con_l">-->
<!--                        <div class="con_label">联系方式</div>-->
<!--                        <div class="con_info con_info_1">市场邮箱：market@xitech.com.cn</div>-->
<!--                        <div class="con_info">公司地址：北京市朝阳区百子湾东朝时代西区109室</div>-->
<!--                    </div>-->
<!--                    <div class="con_r">-->
<!--                        <div class="code_box">-->
<!--                            <img src="https://resource.51beauty.com.cn/9ffef6d3-eed5-4727-baa9-5d018bb12849?imageslim" />-->
<!--                            <div class="code_name">医美行业</div>-->
<!--                        </div>-->
<!--                        <div class="code_box">-->
<!--                            <img src="https://resource.51beauty.com.cn/e8e6d67a-9e72-4d83-a731-94614bf18e5f?imageslim" />-->
<!--                            <div class="code_name">口腔行业</div>-->
<!--                        </div>-->
<!--                        <div class="code_more">-->
<!--                            <div class="prompt">更多详情</div>-->
<!--                            <div class="prompt">请添加云晰营销官企业微信</div>-->
<!--                            <div class="prompt">随时为您答疑解惑</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
<!--            <div class="call" @click="openAppoint">-->
<!--                <img src="../assets/fuceng.png" alt="">-->
<!--            </div>-->
            <Bottom @openWord="openWord"/>
            <appointment :visibleDialog="appointmentVisible" @closeAppoint="closeAppoint" />
            <word :wordDialog="wordVisible" @closeWord="closeWord" />
            <Contact />
        </div>
    </div>
</template>
<script>
import NavBar from '@/components/navBar.vue'
import Bottom from '@/components/bottom.vue'
import Appointment from '@/components/appointment.vue'
import Word from '@/components/word.vue'
import Contact from '@/components/contact.vue'
import { WOW } from 'wowjs'
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
var swiper = {}
export default {
    name: 'home',
    components: {
        NavBar,
        Bottom,
        Appointment,
        Word,
        Contact
    },
    data() {
        return {
            status: [],
            type: [],
            product: [
              {
                'icon': 'https://resource.51beauty.com.cn/a9a0ceb2-766d-4e62-9321-f2d8c6ed9c14?imageslim', // 默认icon
                'title': '私域直播', // 主标题
                'path': '/live',
                'subtitle1': '提供完整的一整套直播服务', // 副标题1
                'subtitle2': '决策引擎实现智能投放', // 副标题2
                'subtitle3': '广告页面用户行为洞察', // 副标题3
              },
              {
                  'icon': 'https://resource.51beauty.com.cn/b3a5a183-4ea4-4002-a577-07c0680ba648?imageslim', // 默认icon
                  'title': '企微SCRM', // 主标题
                  'path': '/scrm',
                  'subtitle1': '全渠道获客及智能分配', // 副标题1
                  'subtitle2': '符合行业SOP操作流程', // 副标题2
                  'subtitle3': '基于标签营销自动化', // 副标题3
              },
              {
                  'icon': 'https://resource.51beauty.com.cn/37567fa7-cf4c-48cd-ae8f-3142b9844f11?imageslim', // 默认icon
                  'title': '院内管理', // 主标题
                  'path': '/management',
                  'subtitle1': '对接HIS打通全链路', // 副标题1
                  'subtitle2': '客户信息全景浏览', // 副标题2
                  'subtitle3': '个人绩效及时有效统计', // 副标题3
              },
              {
                'icon': 'https://resource.51beauty.com.cn/3ec440c9-fb46-465b-a42e-0f87317a65b9?imageslim', // 默认icon
                'title': '单病种数字化体系', // 主标题
                'path': '/digitize',
                'subtitle1': '提升患者诊疗体验', // 副标题1
                'subtitle2': '减轻医务人员工作量', // 副标题2
                'subtitle3': '执行过程可量化可追踪', // 副标题3
              },
              {
                'icon': 'https://resource.51beauty.com.cn/12a6de73-ad3a-4064-bb02-6e4f3169b0c0?imageslim', // 默认icon
                'title': '会员管理体系', // 主标题
                'path': '/member',
                'subtitle1': '轻松构建会员标签体系', // 副标题1
                'subtitle2': '构建科学会员成长体系', // 副标题2
                'subtitle3': '会员积分系统促活', // 副标题3
              },
              {
                'icon': 'https://resource.51beauty.com.cn/fdf2372c-7423-4b86-966a-876b75adb76b?imageslim', // 默认icon
                'title': '流量获客', // 主标题
                'path': '/advertising',
                'subtitle1': '对接主流广告投放平台', // 副标题1
                'subtitle2': '决策引擎实现智能投放', // 副标题2
                'subtitle3': '广告页面用户行为洞察', // 副标题3
              }
            ],
            tabList: [
              { name: '口腔',value: 0 },
              { name: '眼科',value: 2 },
              { name: '中医',value: 3 },
              { name: '医美',value: 1 },
            ], // 行业类型
            curTab: 0,  // 当前切换的行业类型
            tabImgs:[
                'https://resource.51beauty.com.cn/68a5e6db-c4ce-4509-abeb-d38816dd0a68?imageslim', // 医美
                'https://resource.51beauty.com.cn/b368cd1b-2cf7-4748-b94d-f733d8669b84?imageslim', // 口腔
                'https://resource.51beauty.com.cn/ec0548db-2b18-4ce3-9f52-499bc6384717?imageslim', // 眼科
                'https://resource.51beauty.com.cn/e676ae51-32e6-483a-a93e-a85bfc3aa438?imageslim' // 植发
            ], // 当前行业对应的行业图
            appointmentVisible: false, // 预约弹层是否显示
            wordVisible: false,
            cur: 0, //默认选中第一个tab
            plans: [
                {
                    img: "https://resource.51beauty.com.cn/8c394f63-1ced-4f32-b44b-482816a617d0?imageslim", // 主图片
                    title: "口腔解决方案", // // 主标题
                    subtitle: "为口腔医疗提供全流程解决方案", // 标题
                    path:'/oral',
                    con1:'-任务提醒',
                    con2:'-话术赋能',
                    con3:'-精准营销',
                    con4:'-建档跟进',
                },
                {
                    img: "https://resource.51beauty.com.cn/5e4f4862-8dbe-4ad7-af47-b6aa288051cb?imageslim", // 主图片
                    title: "医美解决方案", // // 主标题
                    subtitle: "为医美行业提供全流程解决方案", // 标题
                    path:'/beauty',
                    con1:'-公私域一体化',
                    con2:'-客户精准画像',
                    con3:'-聊天内容存档',
                    con4:'-客户资源保全',
                },
                {
                    img: "https://resource.51beauty.com.cn/bd9edb96-31d9-47f9-a899-182e97f29163?imageslim",
                    title: "眼科解决方案", // // 主标题
                    subtitle: "为眼科医疗提供全流程解决方案", // 标题
                    path:'/eye',
                    con1:'-高效管理线索',
                    con2:'-快速获客分享',
                    con3:'-掌握客户意向',
                    con4:'-协同服务客户',
                },
                {
                    img: "https://resource.51beauty.com.cn/fe722a1f-3b03-42f9-900e-9bf67923c0fc?imagesli",
                    title: "中医解决方案", // // 主标题
                    subtitle: "为中医行业提供全流程解决方案", // 标题
                    path:'/hair',
                    con1:'-全线索管理',
                    con2:'-流程标准化',
                    con3:'-高粘性运营',
                    con4:'-个性化定制',
                }
            ],
            logoList:[
                'https://resource.51beauty.com.cn/36723e1a-191c-48b2-906c-9025907d3f0d?imageslim',
                'https://resource.51beauty.com.cn/d9e1d62a-4bfc-4cc9-bb0d-dd40908fc6e9?imageslim',
                'https://resource.51beauty.com.cn/f291f046-b7e8-47ce-9631-c6d9d862dc54?imageslim',
                'https://resource.51beauty.com.cn/4b0282b8-0228-44af-b894-1af18a6d70f9?imageslim',
                'https://resource.51beauty.com.cn/39109535-2033-47ec-b608-6590dc967a13?imageslim',
                'https://resource.51beauty.com.cn/268c377e-7d4f-4188-8a65-f049967bd073?imageslim',
                'https://resource.51beauty.com.cn/e539333c-2f51-467c-86c1-d84d9943fd17?imageslim',
                'https://resource.51beauty.com.cn/75ce1603-7144-4210-98af-f5ac19ddc316?imageslim',
                'https://resource.51beauty.com.cn/34c6927b-652a-4a4a-b3a3-29582e186b88?imageslim',
                'https://resource.51beauty.com.cn/0ddf30fd-af90-40de-a9fb-0a67051a636d?imageslim',
                'https://resource.51beauty.com.cn/3244eaa8-580d-4d3f-befc-3b8475bc4962?imageslim',
                'https://resource.51beauty.com.cn/ac41529f-dc08-422e-bf84-1071bf53eb09?imageslim',
                'https://resource.51beauty.com.cn/e4e20cb4-b8f0-45b8-8a50-53caa561c842?imageslim',
                'https://resource.51beauty.com.cn/5743e0f9-76a7-40b1-84ec-8b3a709a7ab0?imageslim',
                'https://resource.51beauty.com.cn/53112e40-7b8f-4b6e-8df7-4f0987db38ff?imageslim',
                'https://resource.51beauty.com.cn/7d6fc345-c99e-43fb-8407-387458d9d4d6?imageslim',
                'https://resource.51beauty.com.cn/638a0387-b3be-47cc-a464-376b3f83a2d8?imageslim',
                'https://resource.51beauty.com.cn/2182a444-f004-4b1a-b2da-c67536c24f73?imageslim',
                'https://resource.51beauty.com.cn/7b7d656b-b73f-47d6-9ab5-40eaea172dcb?imageslim',
                'https://resource.51beauty.com.cn/4fff0d2f-3c6a-4442-bdb3-e178bbfbf1fa?imageslim',
                'https://resource.51beauty.com.cn/5cd47cad-05cf-4032-abf9-8ef695225f57?imageslim',
                'https://resource.51beauty.com.cn/5c6b41e7-d6e6-48c7-b573-a58ae35d0cd1?imageslim',
                'https://resource.51beauty.com.cn/800e782d-d43d-4b9a-8a96-15b82dfdcec6?imageslim',
                'https://resource.51beauty.com.cn/33c4cda8-da26-4f4f-8965-54c7ac2ffa2c?imageslim',
                'https://resource.51beauty.com.cn/cfbc0da0-cd11-4f33-9397-149fad38f37f?imageslim',
                'https://resource.51beauty.com.cn/26e8bc6f-6741-4262-a461-b1616e16a118?imageslim',
                'https://resource.51beauty.com.cn/e66e02aa-4c81-4241-b3a1-8e335261ca2e?imageslim',
                'https://resource.51beauty.com.cn/52f202cd-7c78-42e6-a858-8d22f391d20e?imageslim',
                'https://resource.51beauty.com.cn/c4b89925-cf1f-45d3-8d71-401ae905d770?imageslim',
                'https://resource.51beauty.com.cn/27c9c791-73d9-4231-975d-ec5beb27c7ef?imageslim',
                'https://resource.51beauty.com.cn/eff3d968-dbd2-4deb-bcdd-5e5c96c38e80?imageslim',
                'https://resource.51beauty.com.cn/72db069b-bb74-4efb-aa3a-9d1a7f26bcbd?imageslim',
                'https://resource.51beauty.com.cn/b2ebc223-0a5c-4505-8b31-2e4215baa1e5?imageslim',
                'https://resource.51beauty.com.cn/11bf663c-a8f9-46ca-9037-8bb1f6c9f0c1?imageslim',
                'https://resource.51beauty.com.cn/bd88cabb-0a8b-4e4d-9d10-142a69e5babb?imageslim',
                'https://resource.51beauty.com.cn/0c1e7a5f-11f8-4831-b530-d839a19619a3?imageslim'
            ],
            swiperCur:0
        }
    },
    mounted() {
        // this.getData()
        new WOW().init()
        let that = this
        swiper = new Swiper('.swiper-container', {
            autoplay:5000,
            speed:1000,
            onSlideChangeStart: function(swiper){
                that.swiperCur = swiper.activeIndex
            }

        })
    },
    methods: {
        skip(path){
            this.$router.push(path)
        },
        toSwiper(index){
            swiper.solidTo(index)
        },
        getData(){
            this.$api.post('/bobo-admin/interface/order/count', {}, response => {
                const data = response.data.status
                const type = response.data.type
                data.map(item=>{
                    item.percent = Number(item.percent)
                })
                type.map(item=>{
                    item.percent = Number(item.percent)
                })
                this.status = data
                this.type = type
                this.getStatus(data)
                this.getType(type)
            });
        },
        setTab (value) {
            if (value !== this.curTab) {
                this.curTab = value
            }
        },
        // 关闭预约弹层
        closeAppoint (e) {
            this.appointmentVisible = e
        },
        closeWord (e) {
            this.wordVisible = e
        },
        openWord () {
            this.wordVisible = true
        },
        // 打开预约弹层
        openAppoint () {
            this.appointmentVisible = true
        },
        // 查看详情-跳转至产品页面
        goPage () {
            this.$router.push({
                path: "/my"
            })
        }
    }

}

</script>
<style scoped lang="less">
    .home {
        position: relative;
        padding-top: 0px;
        .swiper_paging{
            width: 23px;
            height: 1px;
            background: rgba(255,255,255,.2);
            border-radius: 1px;
            margin: 0 auto;
            margin-top: 10px;
            display: flex;
            >div{
                width: 8px;
                transition: .5s;
                border-radius: 1px;
            }
        }
        .segmentation{
            width: 100%;
            height:.3px;
            background: #000000;
            opacity: 0.1;
        }
        .call{
            position: fixed;
            bottom: 50px;
            left: 335px;
            img{
                width: 30px;
                height: 30px;
            }
        }
        .flex_column{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .flex{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .product_list_con{
          margin-top: 16px;
          width: 250px;
          flex-wrap: wrap;
          justify-content: space-between;
            .product_item{
                width: 78px;
                height: 83px;
                text-align: center;
                transition: .3s ease-in-out;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                .icon{
                    img{
                        width: 31px;
                        height: 31px;
                        display: block;
                        margin: 0 auto;
                        margin-top: 3px;
                    }
                }
                .title{
                    color: #2C68FF;
                    font-size: 5px;
                    margin-top: 6px;
                    font-weight: bold;
                    margin-bottom: 4px;
                }
                .sub_title{
                    color: #273755;
                    font-size: 3px;
                    opacity: .5;
                    div{
                        margin-bottom: 1px;
                    }
                }
                .next{
                    position: absolute;
                    left: 34px;
                    bottom: -10px;
                    transition: .6s ease-out;
                    opacity: 0;
                    display: flex;
                    align-items: center;
                    font-size: 3px;
                    img{
                        width: 6px;
                        height: 6px;
                        margin: 0 auto;
                        display: block;
                    }
                }

            }
            .product_item:hover{
                box-shadow: 0px 2px 4px 0px rgba(55,99,170,0.1);
                border-radius: 2px;
            }
            .product_item:hover .next{
                bottom: 4px;
                /*left: 32px;*/
                opacity: 1;
                color: #2C68FF;
            }
        }
        .tab_bolck{
            background: #FAFBFD;
            margin-top: 20px;
        }
        .content{
            display: flex;
            align-items: center;
            flex-direction: column;
            .banner{
                width: 375px;
                height: 145px;
                background: url('https://resource.51beauty.com.cn/9d0c339f-7000-4596-b5b7-4c85fd83e4f2?imageslim') no-repeat;
                background-size: cover;
                .banner_info{
                    padding-top: 18px;
                    padding-left: 70px;
                }
                .banner_title{
                    margin-top: 37px;
                }
                .title{
                    font-size: 23px;
                    font-weight: 500;
                    color: #2C68FF;
                    margin-top: -2px;
                }
                .last_title{
                    height: 4px;
                    font-size: 8px;
                    font-weight: 400;
                    color: #273755;
                    margin-top: 4px;
                    letter-spacing: 2px;
                }
            }
            .info{
                box-sizing: border-box;
                width: 375px;
                .title{
                    text-align: center;
                }
                .first_title{
                    font-size: 7.5px;
                    font-weight: bold;
                    color: #000;
                    margin-top: 29px;
                    span{
                        color: #196FF1;
                    }
                }
                .img_title{
                    font-size: 7px;
                    color: #fff;
                    font-weight: 500;
                    margin-top: 22px;
                }
                .last_title{
                    font-size: 4px;
                    font-weight: 400;
                    color: #273755;
                    margin-top: 4px;
                }


                .tab_box{
                    margin-top: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .tab_item{
                        width: 39px;
                      
                        position: relative;
                        border-left: 0;
                        font-size: 5px;
                        color: #333333;
                        transition: all 0.35s;
                        cursor: pointer;
                        font-weight: bold;
                        text-align: center;
                    }
                    .line{
                        width: 12px;
                        height: 1px;
                        background: #2C68FF;
                        margin: 0 auto;
                        margin-top: 4px;
                        transition:all .3s ease-in-out;
                    }
                    .tab_item:hover{
                        color: #2278F0;
                    }
                    .tab_item.tab_active{
                        color: #2C68FF;
                    }
                }
                .tab_img{
                    margin-top: 14px;
                    width: 235px;
                    img{
                        width: 235px;
                        height: auto;
                        vertical-align: top;
                    }
                }
            }

            .contact{
                width: 375px;
                padding: 12px 70px 10px;
                background: #2278F0;
                margin-top: 24px;
                color: #fff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .con_l{
                    .con_label{
                        font-size: 6px;
                        padding-bottom: 8px;
                    }
                    .con_info{
                        font-size: 4px;
                        line-height: 7px;
                    }
                    .con_info.con_info_1 {
                        padding-bottom: 3px;
                    }
                }
                .con_r{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .code_box{
                        width: 29px;
                        margin-right: 4px;
                        background: rgba(255, 255, 255, 0.1);
                        img{
                            width: 29px;
                            height: auto;
                            vertical-align: top;
                        }
                        .code_name{
                            font-size: 4px;
                            text-align: center;
                            padding: 2px 0;
                        }
                    }
                    .code_more{
                        .prompt{
                            font-size: 4px;
                            line-height: 6px;
                        }
                    }
                }
            }
        }
        .tab_content{
            display: flex;
            margin-top: 11px;
            padding-bottom: 30px;
            .tab_content_left{
                img{
                    width: 156px;
                    display: block;
                }
            }
            .tab_content_right{
                width: 76px;
                padding-left: 20px;
                font-size: 4px;
                color: #273755;
                .tab_content_right_title{
                    margin-top: 7px;
                    font-size: 6px;
                    font-weight: bold;
                    color: #000;
                }
                .tab_content_right_subtitle{
                    margin: 2px 0 8px 0;
                    color: #273755;
                    opacity: .5;
                }
                .tab_content_right_list{
                    margin-bottom: 4px;
                }
                .tab_content_right_btn{
                    width: 33px;
                    height: 10px;
                    border-radius: 1px;
                    border: .3px solid #98A3B7;
                    color: #98A3B7;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top:10px;
                    transition: .3s;
                    cursor: pointer;

                }
                .tab_content_right_btn:hover{
                    color: #fff;
                    border-color: #2C68FF !important;
                    background: #2C68FF !important;
                }
            }
        }
        .logo_content{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 300px;
            img{
                width: 43px;
                height: 18px;
                display: block;
                margin-top: 10px;
            }
        }
        .doctor{
            height: 95px;
            width: 375px;
            background: #2C68FF;
            color: #fff;
            margin-top: 29px;
            .swiper-container{
                width: 186px;
                margin: 0 auto;
                position: relative;
                margin-top: 20px;
                .quotes_left{
                    position: absolute;
                    left: 0;
                    top: 0;
                    img{
                        width: 8px;
                        height: 8px;
                        display: block;
                    }
                    z-index: 99;
                }
                .quotes_right{
                    position: absolute;
                    right: 0px;
                    bottom: 10px;
                    z-index: 99;
                    img{
                        width: 8px;
                        height: 8px;
                        display: block;
                    }
                }
            }
            .doctor_content{
                width: 186px;
                margin: 0 auto;
                /*margin-top: 8px;*/
                background: #2C68FF;
                .doctor_introduce{
                    padding-top: 12px;
                    width: 153px;
                    height: 33px;
                    margin: 0 auto;
                    font-size: 3px;
                    line-height: 5px;
                }
                .doctor_info{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                    img{
                        width: 11px;
                        height: 11px;
                        display: block;
                        border-radius: 50%;
                    }
                    >div:nth-child(2){
                        font-size: 4px;
                        margin-left: 3px;
                        font-weight: bold;
                        >div:nth-child(2){
                            font-size: 3px;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }
</style>
